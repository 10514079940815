import { Injectable } from '@angular/core';
import { ProjectMinimal } from './user-data.service';
import { addDoc, collection, deleteField, doc, DocumentReference, DocumentSnapshot, FieldValue, getDoc, getFirestore, setDoc, updateDoc } from 'firebase/firestore';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  constructor() {
  }

  public addNewProject(project: ProjectMinimal, userId: string): Promise<DocumentReference> {
    const newProject: Project = {
      name: project.name,
      type: project.type,
      owner: userId,
      creationDate: new Date().getTime()
    }
    return addDoc(collection(getFirestore(), 'projects'), newProject)
  }

  public getProject(id: string) {
    const ref = doc(getFirestore(), 'projects', id);

    return getDoc(ref).then((snapshot: DocumentSnapshot<Project>) => {
      if (!snapshot.exists) {
        throw new Error(`Project with ${id} doens't exist`);
      }

      return snapshot.data();
    }, error => console.log(error));
  }

  public getProjectRef(id: string) {
    const ref = doc(getFirestore(), 'projects', id);
    return ref;
  }

  public changeProjectName(projectId: string, newName: string) {
    const projectRef = doc(getFirestore(), 'projects', projectId);
    return setDoc(projectRef, {
      name: newName
    }, { merge: true });
  }

  public changeOverquotaPolicy(projectId: string,  policy: 'extra' | 'block') {
    const projectRef = doc(getFirestore(), 'projects', projectId);
    const updates = {};
    updates[`billing.overquota`] = policy;
    return updateDoc(projectRef, updates);
  }

  public changeKeyDescription(projectId: string, keyId: string, description: any): Promise<void> {
    const projectRef = doc(getFirestore(), 'projects', projectId);
    const updates = {};
    updates[`keys.${keyId}.description`] = description;
    return updateDoc(projectRef, updates);
  }

  public changeProjectPendingPlan(projectId: string, plan: string, cycle: string): Promise<void> {
    const projectRef = doc(getFirestore(), 'projects', projectId);

    const updates = {};
    updates["billing.pendingPlan"] = plan;
    updates["billing.pendingPlanCycle"] = cycle;
    updates["billing.renewalDate"] = deleteField();
    updates["billing.expirationDate"] = deleteField();
    return updateDoc(projectRef, updates);
  }

  public confirmProjectPendingPlan(projectId: string, plan: string): Promise<void> {
    const projectRef = doc(getFirestore(), 'projects', projectId);

    const updates = {};
    updates['billing.plan'] = plan;
    updates['billing.pendingPlan'] = deleteField();
    return updateDoc(projectRef, updates);
  }

  public addLog(error): Promise<void> {
    const projectRef = doc(getFirestore(), 'logs', (new Date()).getTime().toString());
    return setDoc(projectRef, {
      error: error
    });
  }
}

export interface Project {
  name: string;
  type: string;
  owner: string;
  creationDate?: number;
  keys?: { [key: string]: ApiKey },
  sharedWith?: { [key: string]: {
    info: string;
    user: string;
  }},
  billing?: Billing;

  shared?: boolean;

  billingOptions?: BillingOptions;
}

export interface BillingOptions {
  optionalPlans: string[];
}

export interface Billing {
  plan: string;
  subscriptionId: string;
  subscriptionStatus: string;
  customerId: string;
  productId: string;
  isYearly: boolean;
  renewalDate: string;
  expirationDate: string;
  pendingPlan: string;
  pendingPlanCycle: string;
  overquota: 'block' | 'extra';
}

export interface ApiKey {
  created?: number;
  id: string;
  description: string;
}

export interface ApiKeyData {
  id: string;
  restriction_addresses: string[];
  restriction_referrers: string[];
  restriction_origins: string[];
  restriction_allowed_origin_header: string;
  usage_counter: number;
  usage_quota: number;
}