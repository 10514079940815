import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from '../environments/environment';
import { TopNavigationComponent } from './components/top-navigation/top-navigation.component';
import { MatButtonModule } from '@angular/material/button';
import { MatNativeDateModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { ApiProjectModule } from './api-project/api-project.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { UserGuard } from './user/canActivate/user-guard';
import { AuthGuard } from './user/canActivate/auth-guard';
import { ManifoldGuard } from './manifold-project/canActivate/manifold-guard';
import { AuthService } from './user/services/auth.service';
import { UserModule } from './user/user.module';
import { ManifoldProjectModule } from './manifold-project/manifold-project.module';
import { MatDividerModule } from '@angular/material/divider';
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { CommonComponentsModule } from './common-components/common-components.module';
import * as firebase from 'firebase/app';
import {NgcCookieConsentModule, NgcCookieConsentConfig} from 'ngx-cookieconsent';
import {ClipboardModule} from '@angular/cdk/clipboard';

firebase.initializeApp(environment.firebase);

const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: environment.production ? 'myprojects.geoapify.com' : 'localhost',
    name: 'my-projects-geoapify'
  },
  palette: {
    popup: {
      background: '#64659a',
      text: 'rgba(255, 255, 255, 0.8)'
    },
    button: {
      background: '#fff',
      text: 'rgba(0, 0, 0, 0.8)'
    }
  },
  position: "bottom-left",
  theme: 'classic',
  "type": "opt-in",
  "content": {
    "message": "We use necessary and statistical cookies to guaranty a high performance of our website and make web pages user-friendly. Our chat widget also depends on statistical cookies, please leave all cookies enabled to be able to contact us with Live Chat.",
    "dismiss": "Allow all",
    "deny": "Allow only necessary",
    "link": "Learn more about Geoapify Cookie policy",
    "href": "https://www.geoapify.com/cookie-policy/",
    "policy": "Cookie Policy"
  }
};

@NgModule({
  declarations: [
    AppComponent,
    TopNavigationComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatMenuModule,
    MatButtonModule,
    MatIconModule,
    ApiProjectModule,
    MatNativeDateModule,
    MatDividerModule,
    MatTabsModule,
    MatButtonToggleModule,
    MatFormFieldModule,
    MatSelectModule,
    FontAwesomeModule,
    UserModule,
    ManifoldProjectModule,
    CommonComponentsModule,
    ClipboardModule,
    NgcCookieConsentModule.forRoot(cookieConfig)
  ],
  providers: [
    UserGuard,
    AuthGuard,
    ManifoldGuard,
    AuthService],
  bootstrap: [AppComponent]
})
export class AppModule { 

}
