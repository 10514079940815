<div class="project-container">
    <div class="left-navigation-container">
        <div class="navigation-container" [class.minimized]="navigationMinimized">
            <div class="navigation-main">
                <div class="navigation-items-container">
                    <div class="navigation-item" *ngFor="let item of navigationItems" (click)=onNavItemClick(item)
                        [class.active]="activePanelId === item.id">
                        <span class="icon">
                            <fa-icon [icon]="['fas', item.icon]"></fa-icon>
                        </span>
                        <span class="label mat-body">{{item.label}}</span>
                    </div>
                </div>
                <div class="navigation-items-container">
                    <div class="navigation-item">
                        <a href="https://apidocs.geoapify.com/" target="_blank" rel="noopener noreferrer"><span
                                class="label mat-body margin-right-10">API Documentation</span>
                            <fa-icon [icon]="['fas', 'external-link-alt']"  matTooltip="API Documentation"></fa-icon>
                        </a></div>
                    <div class="navigation-item">
                        <a href="https://apidocs.geoapify.com/" target="_blank" rel="noopener noreferrer"><span
                                class="label mat-body margin-right-10">API Playground</span>
                            <fa-icon [icon]="['fas', 'external-link-alt']" matTooltip="API Playground"></fa-icon>
                        </a>
                    </div>
                    <div class="navigation-item" *ngFor="let item of docs">
                        <a [href]="item.url" target="_blank" rel="noopener noreferrer">
                            <span class="icon white-06 margin-left-20">
                                <fa-icon [icon]="['fas', item.icon]" size="sm"></fa-icon>
                            </span>
                            <span class="label mat-caption white-06">{{item.label}}</span>
                        </a>
                    </div>
                    <div class="navigation-item">
                        <a href="https://apidocs.geoapify.com/" target="_blank" rel="noopener noreferrer"><span
                                class="label mat-body margin-right-10">Code samples</span>
                            <fa-icon [icon]="['fas', 'external-link-alt']" matTooltip="Code samples"></fa-icon>
                        </a>
                    </div>

                </div>
            </div>
            <div class="navigation-buttons">
                <button mat-icon-button (click)="navigationMinimized = !navigationMinimized">
                    <fa-icon [icon]="['fas', 'chevron-right']" *ngIf="navigationMinimized"></fa-icon>
                    <fa-icon [icon]="['fas', 'chevron-left']" *ngIf="!navigationMinimized"></fa-icon>
                </button>
            </div>
        </div>
    </div>
    <div class="main-container">
        <ng-container [ngSwitch]="activePanelId">
            <ng-container *ngSwitchCase="'keys'">
                <div class="project-item-container">
                    <app-data-widget [widgetCaption]="'API keys'">
                        <div class="keys-container">
                            <div class="key-container" *ngFor="let apiKey of keys; let i = index">
                                <app-key-item [key]="apiKey" [openStates]="openStates" onlyKey="true"></app-key-item>
                            </div>
                        </div>
                    </app-data-widget>
                </div>
            </ng-container>
            <ng-container *ngSwitchCase="'statistics'">
                <app-keys-statistics [apiKeys]="keys"></app-keys-statistics>
            </ng-container>
        </ng-container>
    </div>
</div>