<app-login-background>
  <span class="mat-title">Restore password</span>
  <form class="restore-form" [formGroup]="restoreForm">

    <mat-form-field class="restore-form-control-fullwidth">
      <input matInput type="email" placeholder="Email" formControlName="email" class="form-control">
      <mat-error *ngIf="!restoreForm.controls['email'].valid">
        Please enter a valid email address
      </mat-error>
    </mat-form-field>

    <re-captcha formControlName="recaptcha" (resolved)="resolved($event)"></re-captcha>

    <div *ngIf="errorMessage" class="restore-form-error-message">
      <span class="mat-caption">{{errorMessage}}</span>
    </div>

    <div class="restore-form-button">
      <button mat-stroked-button type="submit" [disabled]="restoreForm.invalid"  (click)="sendRestorePasswordEmail(restoreForm.value)" class="btn btn-default">Restore password</button>
    </div>

  </form>
</app-login-background>
