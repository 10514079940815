<div>
  <div class="controls">
    <div [class.margin-bottom-20] = "api">
      <mat-form-field class="fullwidth">
        <mat-label>Choose a Geoapify API</mat-label>
        <mat-select [(ngModel)]="api" (selectionChange)="generateApiUrl()">
          <mat-option *ngFor="let apiData of geoapifyAPIs" [value]="apiData">{{apiData.label}}</mat-option>
        </mat-select>
        <mat-hint *ngIf="api"><a target="_blank" [href]="api.url">{{api.urlLabel || 'Open in Playground'}}</a></mat-hint>
      </mat-form-field>
    </div>
    <mat-form-field class="fullwidth" *ngIf="keys && keys.length">
      <mat-label>Choose an API key</mat-label>
      <mat-select [(ngModel)]="key" (selectionChange)="generateApiUrl()">
        <mat-option *ngFor="let keyItem of keys" [value]="keyItem.id">{{keyItem.id}}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="fullwidth" *ngIf="!keys?.length">
      <mat-label>Enter an API key</mat-label>
      <input matInput type="text" [(ngModel)]="key" (change)="generateApiUrl()">
    </mat-form-field>

    <div class="flex">
      <span class="protocol-value margin-right-5" *ngIf="api">
        {{ api.type === 'post' ? "POST" : "GET" }}
      </span>

      <mat-form-field class="flex-1">
        <mat-label>API URL example</mat-label>
        <input matInput type="text" [value]="apiUrl">
      </mat-form-field>
      <span *ngIf="apiUrl">
        <button color="default" mat-icon-button aria-label="Copy" (click)="copyValue(apiUrl)">
          <fa-icon class="black-08" [icon]="['fas', 'copy']"></fa-icon>
        </button>
      </span>
      <span *ngIf="apiUrl && key && !api.skipTry">
        <button mat-raised-button color="primary" (click)="tryUrl()">Try</button>
      </span>
    </div>
  </div>

  <div class="controls mat-caption margin-bottom-10" *ngIf="api && api.type==='post'">
    <div class="mat-caption black-06">Body example</div>

    <div class="object-sample">
      <button class="copy-button" color="default" mat-icon-button aria-label="Copy"
        (click)="copyValue(api.params, true)">
        <fa-icon class="black-08" [icon]="['fas', 'copy']"></fa-icon>
      </button>

      <ngx-json-viewer [json]="api.params" [expanded]="false"></ngx-json-viewer>
    </div>
  </div>

  <div class="controls mat-caption" *ngIf="testResult || inProgress">

    <div class="mat-caption black-06">Result</div>

    <div class="spinner" *ngIf="inProgress">
      <mat-spinner diameter="30"></mat-spinner>
    </div>

    <div class="object-sample" *ngIf="testResult">
      <button class="copy-button" color="default" mat-icon-button aria-label="Copy"
        (click)="copyValue(testResult, true)">
        <fa-icon class="black-08" [icon]="['fas', 'copy']"></fa-icon>
      </button>

      <ngx-json-viewer [json]="testResult" [expanded]="false"></ngx-json-viewer>
    </div>
  </div>

  <div class="controls mat-caption center" *ngIf="api && api.type === 'image'">
    <img [src]="apiUrl">
  </div>

  <div class="code-templates" *ngIf="api && !api.skipCode && (api.type === 'get' || api.type === 'post')">
    <mat-tab-group>
      <mat-tab [label]="codeSample.label" *ngFor="let codeSample of codeTemplates[api.type]">
        <span class="copy-button margin-top-5"><button color="default" mat-icon-button aria-label="Copy"
            (click)="copyValue(codeSample.codePrepared)">
            <fa-icon class="white-08" [icon]="['fas', 'copy']"></fa-icon>
          </button>
        </span>
        <pre [class]="'language-' + codeSample.lang"><code [innerHtml]="codeSample.codeStyled"></code></pre>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>