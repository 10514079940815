import { Injectable } from '@angular/core';
import * as firebase from 'firebase/app';
import 'firebase/functions';
import { getFunctions, HttpsCallable, httpsCallable } from 'firebase/functions';

@Injectable({
  providedIn: 'root'
})
export class KeyManagementService {
  private getApiKeyDataFunc: HttpsCallable = httpsCallable(getFunctions(), 'getApiKeyData');
  private getApiKeyStatFunc: HttpsCallable = httpsCallable(getFunctions(),'getApiKeyStatistics');
  private createApiKeyFunc: HttpsCallable = httpsCallable(getFunctions(),'createApiKey');
  private updateApiKeyFunc: HttpsCallable = httpsCallable(getFunctions(),'updateApiKey');
  private removeApiKeyFunc: HttpsCallable = httpsCallable(getFunctions(),'removeApiKey');

  constructor() {
  }

  public getApiKeyData(key: string) {
    return this.getApiKeyDataFunc({
      key: key
    }).then(result => result.data);
  }

  public createApiKey(projectId: string, comment: string): Promise<any> {
    return this.createApiKeyFunc({
      projectId: projectId,
      comment: comment
    }).then(result => result.data);
  }

  public updateApiKey(key: string, data: any, comment: string) {
    data.key = key;
    data.comment = comment;
    return this.updateApiKeyFunc(data).then(result => result.data);
  }

  public removeApiKey(key: string, projectId: string) {
    return this.removeApiKeyFunc({
      key: key,
      projectId: projectId
    });
  }

  public getApiKeyStatistics(key: string, fromDate: string, toDate: string, splitByDays?: boolean): Promise<any> {

    const params: any = {
      apiKey: key,
      fromDate: fromDate,
      toDate: toDate
    };

    if (splitByDays) {
      params.split='day';
    }

    return this.getApiKeyStatFunc(params).then(result => result.data);
  }

  public generateComment(userId: string, projectId: string, plan?: string) {
    return `User: ${userId}, project: ${projectId}, plan: ${plan || 'geoapify-api-free'}`
  }
}

export interface ApiKeyStatistics {
  tiles: number;
  isoline: number;
  routing: number;
  places: number;
  placedetails: number;
  geocoding: number;
  geolocation: number;
  datastore: number;
  batch: number;
  boundaries: number;
  icon: number;
  mapmatching: number;
  routematrix: number;
  routeplanner: number;
  staticmap: number;
}