<div class="pricing-plans-container" *ngIf="mainPricingPlans">
  <div class="spinner-container" *ngIf="inProgress">
    <mat-spinner diameter="50"></mat-spinner>
  </div>

  <div class="pricing-options">
    <mat-button-toggle-group class="smaller" #group="matButtonToggleGroup" [(value)]="billingCycle">
      <mat-button-toggle value="monthly">
        <span class="mat-caption pricing-time-option">Monthly</span>
      </mat-button-toggle>
      <mat-button-toggle class="mat-caption pricing-time-option" value="yearly">
        <span class="mat-caption pricing-time-option">Yearly</span>
      </mat-button-toggle>
    </mat-button-toggle-group>

    <mat-form-field class="margin-left-20 margin-right-10" [style.width]="'100px'">
      <mat-label>Currency</mat-label>
      <mat-select [(value)]="currentCurrency">
        <mat-option value="usd">USD</mat-option>
        <mat-option value="eur">EUR</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="plan-options" *ngIf="currentProject">
    <mat-tab-group [style.width]="'100%'">
      <mat-tab label="Geoapify Location Platform">
        <div class="pricing-plans">
          <div class="pricing-plan" *ngFor="let plan of mainPricingPlans">
            <app-api-pricing-plan-item [isCurrentPlan]="plan.id === currentPlanId" [plan]="plan"
              [renewalDate]="renewalDate" [expirationDate]="expirationDate" (subscribe)="subscribeToPricingPlan(plan)"
              [currency]="currentCurrency" [billingCycle]="billingCycle">
            </app-api-pricing-plan-item>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="More plans" *ngIf="optionalPlansToDisplay?.length">
        <div class="pricing-plans">
          <div class="pricing-plan" *ngFor="let plan of optionalPlansToDisplay">
            <app-api-pricing-plan-item custom="true" [isCurrentPlan]="plan.id === currentPlanId" [plan]="plan"
              [currency]="plan.currency" (subscribe)="subscribeToPricingPlan(plan)" [currency]="currentCurrency"
              [renewalDate]="renewalDate" [expirationDate]="expirationDate" [billingCycle]="billingCycle">
            </app-api-pricing-plan-item>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>

  <!-- PAY_AS_YOU_GO_TOGGLE
  <div class="mat-caption black-06">* You can enable the PayAsYouGo option to pay for extra credits if you go over your
    limits.</div>
  -->

  <!--
  c
    <div class="pricing-plan" *ngFor="let plan of pricingPlans">
      <app-api-pricing-plan-item [isCurrentPlan]="isCurrentPlan(plan)" [plan]="plan" [currency]="plan.currency" (subscribe)="subscribeToPricingPlan(plan)"></app-api-pricing-plan-item>
    </div>
  </div>

  <div class="pricing-plans optional" *ngIf="optionalPlansToDisplay?.length">
    <div class="pricing-plan" *ngFor="let plan of optionalPlansToDisplay">
      <app-api-pricing-plan-item custom="true" [isCurrentPlan]="isCurrentPlan(plan)" [plan]="plan" [currency]="plan.currency" (subscribe)="subscribeToPricingPlan(plan)"></app-api-pricing-plan-item>
    </div>
  </div>-->


  <div class="more-info">
    <span class="mat-body message">Have questions? Check our <a target="_blank" rel="noopener noreferrer"
        href="https://www.geoapify.com/pricing/">FAQ</a> or contact us at <a
        href="mailto:info@geoapify.com">info@geoapify.com</a>.</span>
  </div>
</div>

<div class="info-container">
  <!-- PAY_AS_YOU_GO_TOGGLE
  <app-data-widget widgetCaption="Overquota policy" borderOnly="true">

    <p class="black-08">
      To help avoid exceeding your API quota, we will send you friendly warnings as your usage approaches 50%, 80%, and
      90% of your plan quota.
    </p>

    <div *ngIf="currentPlan && currentPlan.overquota">
      <label id="overquota-radio-group-label">Please choose the overquota policy that is right for your project:</label>
      <mat-radio-group aria-labelledby="overquota-radio-group-label" class="overquota-radio-group margin-top-10"
        [(ngModel)]="currentProject.billing.overquota" [disabled]="currentPlan.overquotaIncluded"
        (change)="overquotaPolicyChange($event)">
        <mat-radio-button class="overquota-radio-button" value="block">
          Limit the API calls when you exceed your plan's quota
        </mat-radio-button>
        <mat-radio-button class="overquota-radio-button margin-top-10" value="extra">
          Pay for extra credits when you exceed your plan's quota (<span class="mat-caption"
            *ngIf="currentCurrency === 'eur'">{{currentPlan.overquota[currentCurrency]}}€ per 1000 credits</span>
          <span class="mat-caption black-06"
            *ngIf="currentCurrency !== 'eur'">${{currentPlan.overquota[currentCurrency]}} per 1000 credits</span>)
        </mat-radio-button>
      </mat-radio-group>
    </div>

    <div class="overquota-warning margin-top-20" *ngIf="!isPaidPlan || currentProject.billing.overquota === 'block'">
      <div class="warning-icon margin-right-10"><fa-icon [icon]="['fas', 'exclamation-triangle']" size="xl"></fa-icon></div>
      <div class="text mat-body-2 black-08">Please be advised that API calls may fail with a 429 code when the API quota is
        exceeded</div>
    </div>

  </app-data-widget >
  -->
  <app-data-widget widgetCaption="Managing billing details" borderOnly="true" *ngIf="isPaidPlan">
    <div>
      <p class="black-08">If you have a custom agreement, please contact our support at <a
          href="mailto:info@geoapify.com">info@geoapify.com</a>.
      </p>
      <p class="black-08">For regular subscriptions, you can manage your billing details via 2Checkout self-service
        portal at <a target="_blank" href="https://secure.2co.com/myaccount/">https://2co.com/</a>: </p>
      <ul class="mat-caption black-08 overlapped-text">
        <li>View detailed information of all products/services purchased through 2Checkout;</li>
        <li>Access the complete details of each product/service, including subscription status, reference number and
          history, costs, invoice, delivery and billing information, registration info, product history, subscription
          reference, and expiration date;</li>
        <li>Manage subscriptions and configure the settings of the renewal process and associated notifications. The
          option to disable automatic subscription renewals is also available;</li>
        <li>Renew (on-demand) subscriptions of purchased software (the Renew now link only becomes visible when a
          subscription is at 90 days or less from expiration);</li>
        <li>Change and update payment information for subscriptions;</li>
        <li>Re-enable the auto-renewal system of subscriptions on-demand.</li>
      </ul>
    </div>
  </app-data-widget>
</div>