<div class="navigation-container" [class.minimized]="minimized">
    <div class="navigation-main">

        <div class="project-selector" *ngIf="activeProject">
            <mat-form-field class="project-selector-control">
                <mat-select [ngModel]="activeProjectId" (selectionChange)="projectChange($event)">
                    <mat-option class="all-options" value="see all">
                        See all projects
                    </mat-option>
                    <mat-divider></mat-divider>
                    <mat-option *ngFor="let project of existingProjects" [value]="project.id">
                        {{project.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="navigation-items-container">
            <div class="navigation-item" *ngFor="let item of items" (click)=onNavItemClick(item) [ngClass]="{active: activeItemId == item.id}">
                <span class="icon black-06">
                    <fa-icon [icon]="['fas', item.icon]"></fa-icon>
                </span>
                <span class="label mat-body">{{item.label}}</span>
            </div>
        </div>
    </div>
    <div class="navigation-buttons">
        <button mat-icon-button (click)="togglePosition()">
            <mat-icon aria-label="Open panel" *ngIf="minimized">chevron_right</mat-icon>
            <mat-icon aria-label="Minimize" *ngIf="!minimized">chevron_left</mat-icon>
        </button>
    </div>
</div>