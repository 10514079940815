<h2 mat-dialog-title>{{ data.title }}</h2>
<mat-dialog-content>  
  <div class="mat-body black-08">{{ data.content }}</div>
  <div class="inpit-container">
    <mat-form-field class="control-fullwidth">
      <input matInput [placeholder]="data.label" [(ngModel)]="data.data" />
    </mat-form-field>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="dialog-buttons">
    <button class="dialog-button" [disabled]="!data.allowEmpty && !data.data" mat-raised-button (click)="okClicked()" color="accent">OK</button>
    <button class="dialog-button" mat-raised-button [mat-dialog-close]="null">Cancel</button>
  </div>
</mat-dialog-actions>