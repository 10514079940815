import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AuthService} from "../../services/auth.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-restore-password',
  templateUrl: './restore-password.component.html',
  styleUrls: ['./restore-password.component.scss']
})
export class RestorePasswordComponent implements OnInit {

  restoreForm: FormGroup;
  errorMessage: string = '';

  constructor(
    private authService: AuthService,
    private router: Router,
    private fb: FormBuilder) {
    this.createForm();
   }

  createForm() {
    this.restoreForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      recaptcha: [null, Validators.required]
    });
  }

  ngOnInit() {
  }

  resolved(captchaResponse: string) {
    //console.log(`Resolved captcha with response ${captchaResponse}:`);
  }

  sendRestorePasswordEmail(value) {
    this.authService.doSendPasswordResetEmail(value.email).then(res => {
      this.router.navigate(['/login']);
    }, err => {

      this.restoreForm.controls.email.setValue('');
      this.restoreForm.controls.email.markAsUntouched();

      this.errorMessage = err.message;
    });
  }
}
