import { EventEmitter, Injectable, OnDestroy } from "@angular/core";
import { getAuth, User, FacebookAuthProvider, signInWithCustomToken, signInWithPopup, sendEmailVerification, GoogleAuthProvider, 
  createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, sendPasswordResetEmail, onAuthStateChanged, EmailAuthProvider,
  reauthenticateWithCredential, updatePassword, updateEmail } from '@firebase/auth';
import { collection, doc, getFirestore, setDoc, Unsubscribe } from "firebase/firestore";
@Injectable()
export class AuthService implements OnDestroy {

  private currentUser: User;
  private userChangeEvent: EventEmitter<User> = new EventEmitter();

  private unsubscribeUserChangeEvent: Unsubscribe;
  private tokenClaims: {
    [key: string]: any;
  } = {};
 

  constructor() {
    this.unsubscribeUserChangeEvent = getAuth().onAuthStateChanged((user) => {
      if (!user) {
        this.currentUser = user;
        this.userChangeEvent.emit(user);
      } else {
        user.getIdTokenResult().then((result) => {
          this.tokenClaims = result.claims;
          this.currentUser = user;
          this.userChangeEvent.emit(user);
        });
      }
    });
  }

  doLoginWithCustomToken(value: string) {
    return new Promise<any>((resolve, reject) => {
      signInWithCustomToken(getAuth(), value)
        .then(res => {
          resolve(res);
        }, err => reject(err))
    })
  }

  doFacebookLogin() {
    return new Promise<any>((resolve, reject) => {
      let provider = new FacebookAuthProvider();
      signInWithPopup(getAuth(), provider)
        .then(res => {
          const user = getAuth().currentUser;

          if (!user.emailVerified) {
            sendEmailVerification(user);
          }
          
          resolve(res);
        }, err => {
          console.log(err);
          reject(err);
        })
    })
  }

  doGoogleLogin() {
    return new Promise<any>((resolve, reject) => {
      let provider = new GoogleAuthProvider();
      provider.addScope('profile');
      provider.addScope('email');
      signInWithPopup(getAuth(), provider)
        .then(res => {
          resolve(res);
        }, err => {
          console.log(err);
          reject(err);
        })
    })
  }

  doRegister(value) {
    return new Promise<any>((resolve, reject) => {
      createUserWithEmailAndPassword(getAuth(), value.email, value.password)
        .then(() => {
          const user = getAuth().currentUser;
          const ref = doc(collection(getFirestore(), 'users'), user.uid);
          setDoc(ref, {
            newsletterConsent: 'skipped'
          }, { merge: true }).then(() => {
            resolve(user);
          }, err => console.log(err));
        })
        .catch(err => reject(err));
    });
  }

  doLogin(value) {
    return new Promise<any>((resolve, reject) => {
      signInWithEmailAndPassword(getAuth(), value.email, value.password)
        .then(res => {
          resolve(res);
        }, err => reject(err))
    })
  }

  doLogout() {
    return new Promise((resolve, reject) => {
      if (getAuth().currentUser) {
        signOut(getAuth()).then(() => {
          this.getUserChangeEvent().emit(null);
          resolve(true);
        });
      }
      else {
        reject();
      }
    });
  }

  doSendPasswordResetEmail(email: string) {
    return new Promise<any>((resolve, reject) => {
      sendPasswordResetEmail(getAuth(), email)
        .then(res => {
          resolve(res);
        }, err => reject(err))
    });
  }

  doSendVerificationEmail() {
    return new Promise<any>((resolve, reject) => {
      this.getUserPromise().then(user => {
        sendEmailVerification(user).then((res) => {
          resolve(res)
        }, err => reject(err));
      })
    });
  }

  public getCurrentUser(): User {
    return this.currentUser;
  }

  public isRegularUser(): boolean {
    return !this.tokenClaims.userType;
  }

  public isManifoldUser(): boolean {
    return this.tokenClaims.userType === 'manifold'
  }

  public getTokenClaims(): {[key: string]: any} {
    return this.tokenClaims;
  }

  public getUserChangeEvent(): EventEmitter<User> {
    return this.userChangeEvent;
  }

  public getUserPromise(): Promise<User> {
    return new Promise<any>((resolve, reject) => {
      const unsubscribe = onAuthStateChanged(getAuth(), (user) => {
        unsubscribe();
        if (user) {
          user.getIdTokenResult().then((result) => {
            this.tokenClaims = result.claims;
            resolve(user);
          });
        } else {
          reject(null);
        }
      });
    });
  }

  ngOnDestroy(): void {
    this.unsubscribeUserChangeEvent();
  }

  getUserTokenId():Promise<string> {
    return this.currentUser.getIdToken();
  }

  setUserPassword(user: User, currentPassword: string, newPassword: string) {
    const credentials = EmailAuthProvider.credential(
      user.email,
      currentPassword
    );
    return reauthenticateWithCredential(user, credentials).then(() => {
      return updatePassword(user, newPassword);
    })
  }

  setUserEmail(user: User, password: string, email: string) {
    const credentials = EmailAuthProvider.credential(
      user.email,
      password
    );
    return reauthenticateWithCredential(user, credentials).then(() => {
      return updateEmail(user, email).then(() => {
        return sendEmailVerification(user);
      });
    })
  }
}
