import { Component, OnInit, OnDestroy, NgZone } from '@angular/core';
import { ActiveApiProjectService } from '../../services/active-api-project.service';
import { ApiKey, Project, ProjectService } from 'src/app/user/services/project.service';
import { KeyManagementService } from 'src/app/user/services/key-management.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-api-project-keys',
  templateUrl: './api-project-keys.component.html',
  styleUrls: ['./api-project-keys.component.scss']
})
export class ApiProjectKeysComponent implements OnInit, OnDestroy {

  openStates: {[key: string]: boolean} = {};
  apiKeys: ApiKey[];
  inProgress: boolean;

  projectDataString = '';
  private projectSub: Subscription;

  constructor(private activeProjectService: ActiveApiProjectService, private projectService: ProjectService,
    private keyManagementService: KeyManagementService, private ngZone: NgZone) { }

  ngOnInit() {
    // load keys
    this.projectSub = this.activeProjectService.getActiveProjectObservable().subscribe((project) => {
      if (!project) {
        return;
      }

      this.projectDataString = this.keyManagementService
        .generateComment(project.owner, this.activeProjectService.getActiveProjectId(), (project.billing || {plan: ''}).plan );

      this.ngZone.run(() => {
        this.apiKeys = this.activeProjectService.getActiveProjectKeys();
      });
    });
  }

  createApiKey() {
    this.inProgress = true;

    this.keyManagementService.createApiKey(this.activeProjectService.getActiveProjectId(), this.projectDataString).finally(() => {
      this.inProgress = false;
    });
  }

  removeApiKey(key: string, index: number) {
    this.inProgress = true;

    this.keyManagementService.removeApiKey(key, this.activeProjectService.getActiveProjectId()).finally(() => {
      this.inProgress = false;
    });
  }

  ngOnDestroy() {
    this.projectSub.unsubscribe();
  }

  changeDescription(apiKey: ApiKey, value) {
    this.projectService.changeKeyDescription(this.activeProjectService.getActiveProjectId(), apiKey.id, value).then(() => {
      apiKey.description = value;
    });
  }
}
