import { NgModule } from '@angular/core';
import { ManifoldDashboardComponent } from './components/manifold-dashboard/manifold-dashboard.component';
import { ManifoldTokenTimeoutComponent } from './components/manifold-token-timeout/manifold-token-timeout.component';
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { CommonComponentsModule } from '../common-components/common-components.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [ManifoldDashboardComponent, ManifoldTokenTimeoutComponent],
  imports: [
    CommonModule,
    CommonComponentsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    FormsModule,
    MatButtonModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatProgressSpinnerModule,
    FontAwesomeModule
  ],
  providers: []
})
export class ManifoldProjectModule { }
