import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-single-property-change-dialog',
  templateUrl: './single-property-change-dialog.component.html',
  styleUrls: ['./single-property-change-dialog.component.scss']
})
export class SinglePropertyChangeDialogComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
    public dialogRef: MatDialogRef<SinglePropertyChangeDialogComponent>) { }

  ngOnInit() {
  }

  okClicked() {
    this.dialogRef.close(this.data.data);
  }
}
