import { Component, OnInit, OnDestroy, NgZone } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FunctionsService } from 'src/app/user/services/functions.service';
import { ActiveApiProjectService } from '../../services/active-api-project.service';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { Project, ProjectService } from 'src/app/user/services/project.service';
import { Subscription } from 'rxjs';
import { ConfirmationDialogComponent } from 'src/app/common-components/components/confirmation-dialog/confirmation-dialog.component';
import { PricingPlan } from 'src/app/common-components/components/api-pricing-plan-item/api-pricing-plan-item.component';
import { KeyManagementService } from 'src/app/user/services/key-management.service';
import { MatRadioChange } from '@angular/material/radio';

@Component({
  selector: 'app-api-project-pricing-plans',
  templateUrl: './api-project-pricing-plans.component.html',
  styleUrls: ['./api-project-pricing-plans.component.scss']
})
export class ApiProjectPricingPlansComponent implements OnInit, OnDestroy {
  allPricingPlans: PricingPlan[];
  mainPricingPlans: PricingPlan[];

  optionalPlansToDisplay: PricingPlan[];

  inProgress: boolean;
  currentProject: Project;
  currentProjectSub: Subscription;

  currentPlanId: string;
  currentPlan: PricingPlan;

  renewalDate: string;
  expirationDate: string;
  isPaidPlan: boolean;

  currentCurrency: 'eur' | 'usd' = 'usd';
  billingCycle: 'monthly' | "yearly" = "monthly";

  constructor(http: HttpClient, private functionService: FunctionsService,
    private activeProjectService: ActiveApiProjectService,
    private dialog: MatDialog,
    private projectService: ProjectService,
    private keyManagerService: KeyManagementService,
    ngZone: NgZone) {

    http.get("assets/json/pricing-plans-new.json").subscribe((data: any) => {

      const products = {};
      const yearlyProducts = [];

      data.filter(plan => plan.product).forEach(plan => {
        products[plan.product.monthly.toString()] = plan.id;
        products[(plan.product.yearly || '').toString()] = plan.id;

        yearlyProducts.push(plan.product.yearly);
      });

      this.mainPricingPlans = data.filter(plan => plan.type === 'credits-based');
      // PAY_AS_YOU_GO_TOGGLE
      this.mainPricingPlans = this.mainPricingPlans.filter(plan => plan.id !== 'geoapify-pay-as-you-go');

      this.allPricingPlans = data;

      if (this.currentProject) {
        const projectOptionalPlans = this.currentProject.billingOptions?.optionalPlans || [];
        this.optionalPlansToDisplay = this.allPricingPlans.filter(
          plan => (projectOptionalPlans.indexOf(plan.id) >= 0 || plan.id === this.currentProject.billing?.plan) && plan.type !== 'credits-based');
          this.currentPlan = this.allPricingPlans.find(plan => plan.id === this.currentPlanId);
        }
    });

    this.currentProjectSub = this.activeProjectService.getActiveProjectObservable().subscribe((project) => {
      this.currentProject = project;
      this.billingCycle = project?.billing?.isYearly ? 'yearly' : this.billingCycle;
      this.currentPlanId = (!project?.billing || !project?.billing.plan) ? 'geoapify-api-free' : project.billing.plan;
      this.isPaidPlan = this.currentPlanId !== 'geoapify-api-free';
      this.renewalDate = project?.billing?.renewalDate ? new Date(project.billing.renewalDate).toLocaleDateString() : null;
      this.expirationDate = project?.billing?.expirationDate ? new Date(project.billing.expirationDate).toLocaleDateString() : null;
      if (this.currentProject?.billing && !this.currentProject.billing.overquota) {
        this.currentProject.billing.overquota = this.currentPlanId === 'geoapify-pay-as-you-go' ? 'extra' : 'block';
      }


      if (this.currentProject && this.allPricingPlans) {
        const projectOptionalPlans = this.currentProject.billingOptions?.optionalPlans || [];
        this.optionalPlansToDisplay = this.allPricingPlans.filter(
          plan => (projectOptionalPlans.indexOf(plan.id) >= 0 || plan.id === this.currentProject.billing?.plan) && plan.type !== 'credits-based');

        this.currentPlan = this.allPricingPlans.find(plan => plan.id === this.currentPlanId);
      }
    });

    try {
      http.get(`${environment.ip_info.url}?apiKey=${environment.ip_info.apiKey}`).subscribe((data: any) => {
        if ((data?.country?.currency || '').toLowerCase() === 'eur'
          || data?.country?.is_in_european_union
          || (data?.continent?.code || '').toLowerCase() === 'eu') {
          ngZone.run(() => {
            this.currentCurrency = 'eur';
          });
        }
      }, err => console.log(err));

    } catch (err) {
      console.log(err);
    }
  }

  ngOnInit() {

  }

  ngOnDestroy() {
    this.currentProjectSub.unsubscribe();
  }

  subscribeToPricingPlan(plan: PricingPlan) {
    if (this.currentProject.billing && this.currentProject.billing.plan ) {
      // subscription exists, need to switch
      this.switchSubscription(plan);
    } else {
      // no subscription yet
      this.openCreateSubscriptionPage(plan);
    }
  }

  switchSubscription(plan: PricingPlan) {
    if (!this.currentProject.billing.subscriptionId) {

      this.dialog.open(ConfirmationDialogComponent, {
        width: '400px',
        data: {
          title: "Change subscription",
          content: "Contact support via E-Mail (info@geoapify.com) to change subscription",
          noCancel: true
        }
      });

      return;
    }

    if (plan.id === 'geoapify-api-free') {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '400px',
        data: {
          title: "Cancel subscription",
          content: "The subscription will be canceled. Cancel now?"
        }
      });

      dialogRef.afterClosed().subscribe(okPressed => {
        if (okPressed) {
          this.inProgress = true;
          this.functionService.removeSubscription(this.activeProjectService.getActiveProjectId(), this.currentProject.billing.subscriptionId).finally(() => {
            Object.keys(this.currentProject.keys || {}).forEach(key => {
              this.keyManagerService.updateApiKey(key, {}, this.keyManagerService.generateComment(this.currentProject.owner, this.activeProjectService.getActiveProjectId()))
            });
            this.inProgress = false;
          });
        }
      });
    } else {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '400px',
        data: {
          title: `Update current subscription`,
          content: `Switch the subscription to the "${plan.label}" pricing plan?`
        }
      });

      dialogRef.afterClosed().subscribe(okPressed => {
        if (okPressed) {
          this.openSwitchSubscriptionPage(plan);
        }
      });
    }
  }

  openCreateSubscriptionPage(plan: PricingPlan) {
    const urlParts = window.location.href.split("/")
    urlParts.pop();
    urlParts.push('subscribed');
    const testPart = environment.production ? '' : '&DOTEST=1';
    const url = `https://secure.2checkout.com/order/checkout.php?PRODS=${plan.product[this.billingCycle]}&QTY=1&CART=1&CARD=1&CURRENCY=${this.currentCurrency.toUpperCase()}&DCURRENCY=${this.currentCurrency.toUpperCase()}`
      + `&CUSTOMERID=${this.activeProjectService.getActiveProjectId()}&BACK_REF=${encodeURI(urlParts.join("/"))}`
      + testPart;

    this.inProgress = true;
    this.projectService.changeProjectPendingPlan(this.activeProjectService.getActiveProjectId(), plan.id, this.billingCycle).then(() => {
      window.open(url, "_self");
    }).finally(() => {
      this.inProgress = false;
    });
  }

  openSwitchSubscriptionPage(plan: PricingPlan) {
    const urlParts = window.location.href.split("/")
    urlParts.pop();
    urlParts.push('subscribed');

    const testPart = environment.production ? '' : '&DOTEST=1';
    const isMonthly = this.billingCycle === 'monthly';

    const url = `https://secure.2checkout.com/order/upgrade.php?LICENSE=${this.currentProject.billing.subscriptionId}`
      + `&CUSTOMERID=${this.activeProjectService.getActiveProjectId()}&BACK_REF=${encodeURI(urlParts.join("/"))}`
      + `&UPGRADEPROD=${isMonthly ? plan.product.monthly : plan.product.yearly}`
      + testPart;

    this.inProgress = true;
    this.projectService.changeProjectPendingPlan(this.activeProjectService.getActiveProjectId(), plan.id, this.billingCycle).then(() => {
      window.open(url, "_self");
    }).finally(() => {
      this.inProgress = false;
    });
  }

  overquotaPolicyChange(event: MatRadioChange) {
    this.projectService.changeOverquotaPolicy(this.activeProjectService.getActiveProjectId(), this.currentProject.billing.overquota);
  }
}
