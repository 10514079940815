import { Injectable } from '@angular/core';
import { Project, ProjectService, ApiKey } from 'src/app/user/services/project.service';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { DocumentSnapshot, onSnapshot } from 'firebase/firestore';

@Injectable({
  providedIn: 'root'
})
export class ActiveApiProjectService {

  private activeProjectValueUnsubscribe;

  private activeProject: Project;
  private activeProjectId: string;
  private activeProjectSubject: BehaviorSubject<Project> = new BehaviorSubject(null);

  private activePanelId: string;
  private activePanelIdSubject: BehaviorSubject<string> = new BehaviorSubject(null);

  constructor(private router: Router, private projectService: ProjectService) { }

  public init(projectId: string, panelId: string) {
    if (projectId === this.activeProjectId) {
      this.setActivePanel(panelId);
      return;
    }

    if (this.activeProjectValueUnsubscribe) {
      this.activeProjectValueUnsubscribe();
    }

    this.activeProjectId = projectId;
    this.setActivePanel(panelId);

    this.activeProjectValueUnsubscribe = onSnapshot(this.projectService.getProjectRef(projectId), (data: DocumentSnapshot<Project>) => {
      this.activeProject = data.data();
      this.activeProjectSubject.next(this.activeProject);
    }, (err) => {
      console.log(`Project with id ${projectId} doesn't exist or you do not have an access`);
      console.log(err.message);
      this.router.navigate(["/projects"]);
      this.activeProjectValueUnsubscribe();
    });

  }

  public getActiveProjectObservable(): Observable<Project> {
    return this.activeProjectSubject.asObservable();
  }

  public getActiveProject(): Project {
    return this.activeProject;
  }

  public getActiveProjectId(): string {
    return this.activeProjectId;
  }


  private setActivePanel(id: string) {
    this.activePanelId = id;
    this.activePanelIdSubject.next(id);
  }

  public getActivePanel() {
    return this.activePanelId;
  }

  public getActivePanelIdObservable() {
    return this.activePanelIdSubject.asObservable();
  }

  public getActiveProjectKeys(): ApiKey[] {
    const keys = Object.keys(this.getActiveProject().keys || {}).map(key => this.getActiveProject().keys[key]);
    keys.sort((a, b) => {
      return a.created - b.created;
  });
    return keys;
  }

  public getActiveProjectKeysIds(): string[] {
    return Object.keys(this.getActiveProject().keys || {});
  }
}
