import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataWidgetComponent } from './components/data-widget/data-widget.component';
import { SinglePropertyChangeDialogComponent } from './components/single-property-change-dialog/single-property-change-dialog.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { KeysStatistics } from './components/keys-statistics/keys-statistics.component';
import { KeyItemComponent } from './components/key-item/key-item.component';
import { MatDividerModule } from '@angular/material/divider';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {MatRadioModule} from '@angular/material/radio';
import { ApiPricingPlanItemComponent } from './components/api-pricing-plan-item/api-pricing-plan-item.component';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faCog, faKey, faCreditCard, faChartBar, faChevronLeft, faSplotch, faExternalLinkAlt, faCheck, faTimes, faMapMarkerAlt, faRoute, faSearch, faMap, 
  faChevronRight, faUserCog, faSignOutAlt, faClone, faInfoCircle, faCopy, faPen, faTrash, faUser, faShareAlt, faPlusCircle, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FooterComponent } from './components/footer/footer.component';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { CodeTemplateComponent } from './components/code-template/code-template.component';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { MatTabsModule } from '@angular/material/tabs';
import { OverviewStatisticsComponent } from './components/overview-statistics/overview-statistics.component';


@NgModule({
    declarations: [
        DataWidgetComponent,
        SinglePropertyChangeDialogComponent,
        ConfirmationDialogComponent,
        KeysStatistics,
        KeyItemComponent,
        ApiPricingPlanItemComponent,
        FooterComponent,
        CodeTemplateComponent,
        OverviewStatisticsComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatDialogModule,
        MatInputModule,
        MatFormFieldModule,
        MatButtonModule,
        MatSelectModule,
        MatIconModule,
        MatDividerModule,
        MatDatepickerModule,
        MatProgressSpinnerModule,
        MatTooltipModule,
        MatTabsModule,
        MatRadioModule,
        FontAwesomeModule,
        MatSnackBarModule,
        NgxJsonViewerModule
    ],
    exports: [
        DataWidgetComponent,
        SinglePropertyChangeDialogComponent,
        KeysStatistics,
        KeyItemComponent,
        FooterComponent,
        ApiPricingPlanItemComponent,
        CodeTemplateComponent,
        FontAwesomeModule,
        MatSnackBarModule
    ],
    providers: [
        { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: true } },
    ]
})
export class CommonComponentsModule { 
  constructor(library: FaIconLibrary) {
    library.addIcons(faCog, faKey, faCreditCard, faChartBar, faChevronLeft, 
      faChevronRight, faMap, faSearch, faRoute, faMapMarkerAlt, faSplotch, faExternalLinkAlt, faCheck, faTimes, faUserCog, faSignOutAlt, faClone, 
      faInfoCircle, faCopy, faPen, faTrash, faUser, faShareAlt, faPlusCircle, faExclamationTriangle);
  }
}
