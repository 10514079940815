import { Injectable } from '@angular/core';
import { CanActivate, Router } from "@angular/router";
import { AuthService } from "../services/auth.service";

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
    public authService: AuthService,
    private router: Router
  ) { }

  canActivate(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.authService.getUserPromise().then(() => {
        if (this.authService.isRegularUser()) {
          this.router.navigate(['/projects']);
          resolve(false);
        } else {
          this.router.navigate(['/manifold/dashboard']);
          resolve(false);
        }
      }, () => {
        resolve(true);
      });
    });
  }
}
