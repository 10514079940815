<app-login-background>
  <span class="mat-title">Sign in</span>
  <form class="login-form" [formGroup]="loginForm">
    <div>
      <mat-form-field class="login-form-control-fullwidth">
        <input matInput type="email" placeholder="Email" formControlName="email" class="form-control" >
      </mat-form-field>
      <mat-form-field class="login-form-control-fullwidth">
        <input matInput type="password" placeholder="Password" class="form-control" formControlName="password">
      </mat-form-field>
      <div *ngIf="errorMessage" class="login-form-error-message">
        <span class="mat-caption">{{errorMessage}}</span>
      </div>
      <div class="login-form-button">
        <button mat-stroked-button type="submit" (click)="tryLogin(loginForm.value)" class="btn btn-default">Sign in</button>
      </div>

    </div>
  </form>

  <div class="login-form-button google">
    <button mat-stroked-button (click)="tryGoogleLogin()" class="btn btn-default">Sign in with Google</button>
  </div>
  <div class="login-form-button facebook">
    <button mat-stroked-button (click)="tryFacebookLogin()" class="btn btn-default">Sign in with Facebook</button>
  </div>

  <div class="login-form-button password-restore">
    <button mat-stroked-button (click)="navigateToRestorePassword()" class="btn btn-default">I forgot my password</button>
  </div>

  <div class="login-form-button registration">
    <button mat-stroked-button (click)="navigateToRegistration()" class="btn btn-default">Register a new account</button>
  </div>
</app-login-background>








