<div class="payment-sources-container">
  <div class="spinner-container" *ngIf="inProgress"><mat-spinner diameter="50"></mat-spinner></div>
  <!--<div class="customer-source-container" *ngFor="let source of sources; let i = index">
    <div class="customer-source-name mat-body">{{ generateName(source) }}</div>
    <div class="customer-source-actions">
      <button mat-icon-button *ngIf="sources.length > 1" (click)="removePaymentSource(source, i)">
        <mat-icon aria-label="Remove payment source">delete</mat-icon>
      </button>
      <div class="customer-source-default mat-caption" *ngIf="isDefault(source)">
          <span>Default source</span>
          <mat-icon aria-label="This is default payment source" color="accent">check_box</mat-icon>
      </div>
      <button mat-raised-button color="accent" *ngIf="!isDefault(source)" (click)="makeSourceDeafault(source)">Make default</button>
    </div>
  </div>-->
  <div class="payment-sources-actions">
    <button mat-icon-button color="accent" (click)="openHandler()">
        <mat-icon aria-label="Add a new payment source">add_circle</mat-icon>
    </button>
  </div>
</div>
