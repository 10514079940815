<div>
  <div class="data-container">

    <div *ngIf="!user.emailVerified" class="user-verification-container">
      <app-data-widget>
        <app-user-verification [userData]="user" ></app-user-verification>
      </app-data-widget>
    </div>

    <app-data-widget class="relative" [widgetCaption]="'Account settings'">
      <div class="relative">
        <div class="spinner" *ngIf="inProgress">
          <mat-spinner diameter="30"></mat-spinner>
        </div>
  
        <div class="mat-caption black-06">You logged in as: </div>
        <div class="mat-body-2 black-08">{{displayName}}</div>
        <div class="margin-top-20" *ngIf="hasPassword && !changeType">
          <span><button mat-raised-button
              (click)="changeType = 'password'">Change password</button></span>
          <span class="margin-left-10"><button mat-raised-button
              (click)="changeType = 'email'">Change email</button></span>
        </div>
  
        <div class="email-form margin-top-20 margin-bottom-20" *ngIf="changeType==='email'">
          <form [formGroup]="emailChangeForm">
            <div>
              <div class="margin-bottom-20">
                <mat-form-field class="fullwidth">
                  <input matInput type="password" placeholder="Current password" class="form-control"
                    formControlName="password">
                </mat-form-field>
  
                <mat-form-field class="fullwidth">
                  <input matInput type="email" placeholder="New email" formControlName="email"
                    class="form-control">
                  <mat-error *ngIf="!emailChangeForm.controls['email'].valid">The provided email is not valid</mat-error>
                </mat-form-field>
  
                <div *ngIf="errorMessage" class="mat-caption color-warning">
                  <span>{{errorMessage}}</span>
                </div>
              </div>
  
              <div class="register-form-button">
                <span class="margin-right-10"><button color="primary" mat-raised-button type="submit"
                    [disabled]="emailChangeForm.invalid" (click)="tryToChangeEmail(emailChangeForm.value)"
                    class="btn btn-default">Change email</button></span>
                <button mat-raised-button (click)="cancelChangeOperation()"
                  class="btn btn-default">Cancel</button>
              </div>
            </div>
          </form>
        </div>
        <div class="password-form margin-top-20 margin-bottom-20" *ngIf="changeType==='password'">
          <form [formGroup]="passwordChangeForm">
            <div>
              <div class="margin-bottom-20">
  
                <mat-form-field class="fullwidth">
                  <input matInput type="password" placeholder="Current password" class="form-control"
                    formControlName="oldPassword">
                </mat-form-field>
  
                <mat-form-field class="fullwidth">
                  <input matInput type="password" placeholder="New password" class="form-control"
                    formControlName="password">
  
                  <mat-error *ngIf="!passwordChangeForm.controls['password'].valid">
                    The provided password is too simple
                  </mat-error>
                </mat-form-field>
  
                <mat-form-field class="fullwidth">
                  <input matInput type="password" placeholder="Confirm password" class="form-control"
                    formControlName="passwordConfirm" [errorStateMatcher]="matcher">
                  <mat-error *ngIf="passwordChangeForm.hasError('notSame')">
                    The provided passwords are not the same
                  </mat-error>
                </mat-form-field>
  
                <div *ngIf="errorMessage" class="color-warning">
                  <span class="mat-caption">{{errorMessage}}</span>
                </div>
              </div>
              <div class="register-form-button">
                <span class="margin-right-10"><button color="primary" mat-flat-button type="submit"
                    [disabled]="passwordChangeForm.invalid" (click)="tryToChangePassword(passwordChangeForm.value)"
                    class="btn btn-default">Change password</button></span>
                <button mat-raised-button (click)="cancelChangeOperation()"
                  class="btn btn-default">Cancel</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </app-data-widget>
    <div class="margin-top-20 margin-bottom-20">
      <mat-divider></mat-divider>
    </div>

    <div class="text-center">
      <button [disabled]="inProgress" mat-raised-button (click)="deleteAccount()">
        <span class="margin-right-10"><fa-icon class="black-08" [icon]="['fas', 'trash']"></fa-icon></span>Delete account
      </button>
    </div>
  </div>
</div>