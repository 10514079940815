import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { User } from 'firebase/auth';
@Component({
  selector: 'app-user-projects',
  templateUrl: './user-projects.component.html',
  styleUrls: ['./user-projects.component.scss']
})
export class UserProjectsComponent implements OnInit, OnDestroy {
  user: User;

  userChangeEventSub: Subscription;

  constructor(private authService: AuthService, private router: Router) {
    this.user = this.authService.getCurrentUser();
    
    this.userChangeEventSub = authService.getUserChangeEvent().subscribe((user) => {
      this.user = user;
      if (!user) {
        this.router.navigate(["/login"]);
      }
    });
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.userChangeEventSub.unsubscribe();
  }
}
