<div class="subscribed-info-container">
  <app-data-widget [widgetCaption]="'Thank you for your order!'">
    <div *ngIf="project">

      <div class="subscription-info-container margin-bottom-20">
        <span class="mat-body black-06 caption">Your subscription plan: </span>
        <span class="mat-title black-08">{{planName}}</span>
      </div>

      <div class="mat-body black-08">
        <p>
          We sent the order details to the email address you specified for billing.
        </p>
        <p>
          Please check your mailbox (spam/junk folder included).
        </p>
        <p>
          As soon as your order is approved, you will receive another email with the payment receipt and tax invoice.
        </p>
      </div>

    </div>
  </app-data-widget>
</div>