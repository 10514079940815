<app-login-background>
  <div class="register-form-button go-to-login">
        <button mat-stroked-button (click)="navigateToLogin()" class="btn btn-default">Go to login page</button>
  </div>
  
  <span class="mat-title">Register a new account</span>
  <form class="register-form" [formGroup]="registerForm">
    <div>
      <mat-form-field class="register-form-control-fullwidth">
        <input matInput type="email" placeholder="Email" formControlName="email" class="form-control">
        <mat-error *ngIf="!registerForm.controls['email'].valid">
          Please enter a valid email address
        </mat-error>
      </mat-form-field>

      <mat-form-field class="register-form-control-fullwidth">
        <input matInput type="password" placeholder="Password (min. 8 characters)" class="form-control" formControlName="password">
        <mat-error *ngIf="!registerForm.controls['password'].valid">
          Min. 8 characters, contain at least 1 [a-z], 1 [A-Z], 1 number
        </mat-error>
      </mat-form-field>

      <mat-form-field class="register-form-control-fullwidth">
        <input matInput type="password" placeholder="Confirm password" class="form-control" formControlName="passwordConfirm"
          [errorStateMatcher]="matcher">
        <mat-error *ngIf="registerForm.hasError('notSame')">
          Passwords do not match
        </mat-error>
      </mat-form-field>

      <div class="register-form-checkbox-container">
        <mat-checkbox class="register-form-checkbox mat-caption" formControlName="policyConcern"><span>I have read and
            accept the <a href="https://geoapify.com/term-and-conditions/" target="_blank">Terms and Conditions</a> and <a href="https://geoapify.com/privacy-policy/" target="_blank">Privacy Policy</a>.</span></mat-checkbox>
      </div>

      <div *ngIf="errorMessage" class="register-form-error-message">
        <span class="mat-body">{{errorMessage}}</span>
      </div>

      <re-captcha formControlName="recaptcha" (resolved)="resolved($event)"></re-captcha>

      <div class="register-form-button">
        <button mat-stroked-button type="submit" [disabled]="registerForm.invalid" (click)="tryRegister(registerForm.value)"
          class="btn btn-default">Register</button>
      </div>

    </div>
  </form>
</app-login-background>