<div class="user-additinal-data-container" *ngIf="userDocument">
    <div>
        <span class="mat-caption">With our newsletters, we send you the latest news, updates on our tutorials and documentation, special offers.</span>
    </div>
    <div>
            <span class="mat-caption" *ngIf="userDocument.newsletterConsent">Congratulations! You subscribed to the newsletters.</span>
            <span class="mat-caption" *ngIf="!userDocument.newsletterConsent">Don't miss it up. Subscribe to the newsletters.</span>
    </div>
    <div class="user-additional-details-buttons">
        <button *ngIf="userDocument.newsletterConsent" mat-raised-button  (click)="toggleSubscription()" class="btn btn-default">Unsubscribe</button>
        <button color="accent" *ngIf="!userDocument.newsletterConsent" mat-raised-button  (click)="toggleSubscription()" class="btn btn-default">Subscribe</button>
    </div>
  </div>
