<div class="project-container">
  <div class="left-navigation-container">
    <app-api-project-navigation></app-api-project-navigation>
  </div>
  <div class="main-container">
    <ng-container [ngSwitch]="activePanelId">
      <ng-container *ngSwitchCase="'settings'">
        <app-api-project-settings></app-api-project-settings>
      </ng-container>
      <ng-container *ngSwitchCase="'keys'">
        <app-api-project-keys></app-api-project-keys>
      </ng-container>
      <ng-container *ngSwitchCase="'statistics'">
        <app-keys-statistics [project]="project" [apiKeys]="keys"></app-keys-statistics>
      </ng-container>      
      <ng-container *ngSwitchCase="'billing'">
        <app-api-project-pricing-plans></app-api-project-pricing-plans>
      </ng-container>
      <ng-container *ngSwitchCase="'subscribed'">
        <app-subscription-change></app-subscription-change>
      </ng-container>
    </ng-container>
  </div>
</div>