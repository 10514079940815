<h2 mat-dialog-title>{{ data.title }}</h2>
<mat-dialog-content>
  <div class="confirmation-dialog-content mat-body">{{ data.content }}</div>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="confirmation-dialog-buttons">
    <button class="confirmation-dialog-button" mat-raised-button [mat-dialog-close]="true" color="accent">OK</button>
    <button class="confirmation-dialog-button" mat-raised-button mat-dialog-close *ngIf="!data.noCancel">Cancel</button>
  </div>
</mat-dialog-actions>
