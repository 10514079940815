import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActiveApiProjectService } from '../../services/active-api-project.service';
import { Project, ProjectService } from 'src/app/user/services/project.service';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { SinglePropertyChangeDialogComponent } from 'src/app/common-components/components/single-property-change-dialog/single-property-change-dialog.component';
import { UserDataService } from 'src/app/user/services/user-data.service';
import { ConfirmationDialogComponent } from 'src/app/common-components/components/confirmation-dialog/confirmation-dialog.component';
import { Router } from '@angular/router';
import { FunctionsService } from 'src/app/user/services/functions.service';
import { AuthService } from 'src/app/user/services/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-api-project-settings',
  templateUrl: './api-project-settings.component.html',
  styleUrls: ['./api-project-settings.component.scss']
})
export class ApiProjectSettingsComponent implements OnInit, OnDestroy {

  projectId: string;
  project: Project;
  projectSub: Subscription;

  inProgress: boolean;

  shareWithEmail: string;
  sharingError: string;

  passProjectEmail: string;
  passProjectError: string;
  isOwner: boolean;


  sharedWith: {
    info: string;
    user: string;
  }[];

  constructor(private activeProjectservice: ActiveApiProjectService, 
    private dialog: MatDialog,
    private userDataService: UserDataService,
    private projectService: ProjectService,
    private router: Router,
    private functionService: FunctionsService,
    private authService: AuthService,
    private snackBar: MatSnackBar) { }

  ngOnInit() {
    this.projectSub = this.activeProjectservice.getActiveProjectObservable().subscribe((project) => {

      this.project = project;

      if (project) {
        this.projectId = this.activeProjectservice.getActiveProjectId();
        this.sharedWith = Object.keys(project.sharedWith || {}).map(userId => project.sharedWith[userId]);
        this.isOwner = project.owner === this.authService.getCurrentUser().uid;
      }
    });
  }

  ngOnDestroy() {
    this.projectSub.unsubscribe();
  }

  editName() {
    const dialogRef = this.dialog.open(SinglePropertyChangeDialogComponent, {
      width: '400px',
      data: {
        label: 'New name',
        data: this.project.name
      }
    });

    dialogRef.afterClosed().subscribe(newValue => {
      if (newValue) {
        this.userDataService.changeProjectName(this.activeProjectservice.getActiveProjectId(), newValue);
        this.projectService.changeProjectName(this.activeProjectservice.getActiveProjectId(), newValue);
      }
    }); 
  }

  deleteProject() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: {title: "Delete project", content: "Delete the project and all existing API keys irrecoverably?"}
    });

    dialogRef.afterClosed().subscribe(okPressed => {
      if (okPressed) {
        this.inProgress = true;
        this.functionService.deleteProject(this.activeProjectservice.getActiveProjectId()).then(() => {
          this.inProgress = false;
          this.router.navigate(["/projects"]);
        });
      }
    });
  }

  copyToClipboard(text: string) {
    var input = document.createElement('input');
    input.setAttribute('value', text);
    document.body.appendChild(input);
    input.select();
    var result = document.execCommand('copy');
    document.body.removeChild(input);

    this.snackBar.open('Copied', 'OK', {
      duration: 2000
    });

    return result;
  }

  shareProject() {
    this.inProgress = true;

    this.functionService.shareProject(this.activeProjectservice.getActiveProjectId(), this.shareWithEmail).then(() => {
      this.shareWithEmail = '';
      this.inProgress = false;
    }, (err) => {
      this.sharingError = err;
      this.inProgress = false;
    });
  }

  unshareProject(user) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '450px',
      data: {title: "Sharing project", content: `Stop sharing the project with ${user.info}?`}
    });

    dialogRef.afterClosed().subscribe(okPressed => {
      if (okPressed) {
        this.inProgress = true;

        this.functionService.unshareProject(this.activeProjectservice.getActiveProjectId(), user.user).then(() => {
          this.inProgress = false;
        }, (err) => console.log(err));
      }
    });
  }

  passProject() {

    const dialogRef = this.dialog.open(SinglePropertyChangeDialogComponent, {
      width: '450px',
      data: {
        title: "Change owner",
        content: `Are you sure you want to transfer the project to ${this.passProjectEmail}? Please type "transfer" to confirm:`,
        label: '',
        data: ''
      }
    });

    dialogRef.afterClosed().subscribe(newValue => {
      if (newValue === "transfer") {
        this.inProgress = true;

        this.functionService.passProject(this.activeProjectservice.getActiveProjectId(), this.passProjectEmail).then(() => {
          this.inProgress = false;
          this.router.navigate(["/projects"]);
        }, (err) => {
          this.passProjectError = err;
          this.inProgress = false;
        });
      }
    });
  }
}
