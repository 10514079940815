<div class="project-settings-container">
  <div class="spinner-container" *ngIf="inProgress">
    <mat-spinner diameter="50"></mat-spinner>
  </div>
  <app-data-widget [widgetCaption]="'Settings'">
    <div *ngIf="project">
      <div class="setting-container black-08">
        <span class="label mat-body-2 setting-item">Project ID: </span>
        <span class="name mat-body setting-item">{{projectId}}</span>
        <span class="edit-button setting-item">
          <button mat-icon-button (click)="copyToClipboard(projectId)" matTooltip="Copy project ID">
            <fa-icon class="black-08" [icon]="['fas', 'copy']"></fa-icon>
          </button>
        </span>
      </div>

      <div class="setting-container black-08">
        <span class="label mat-body-2 setting-item">Project name: </span>
        <span class="name mat-body setting-item">{{project.name}}</span>
        <span class="edit-button setting-item" *ngIf="isOwner">
          <button mat-icon-button (click)="editName()" matTooltip="Change name">
            <fa-icon class="black-08" [icon]="['fas', 'pen']"></fa-icon>
          </button>
        </span>
      </div>
    </div>

    <div class="setting-container black-08" *ngIf="!isOwner">
      <span class="label mat-body-2 setting-item">Project owner: </span>
      <span class="name mat-body setting-item">{{project?.ownerInfo}}</span>
    </div>
  </app-data-widget>

  <app-data-widget [widgetCaption]="'Sharing'" *ngIf="isOwner">
    <div *ngIf="project">
      <div class="flex black-08">
        <mat-form-field class="flex-1">
          <mat-label>Email address of another registered user</mat-label>
          <input matInput placeholder="Enter an email address to share the project" type="email" [(ngModel)]="shareWithEmail"
            (change)="sharingError = ''">
          <mat-hint *ngIf="sharingError"><span class="color-warning">{{sharingError}}</span></mat-hint>
        </mat-form-field>
        <span class="margin-left-10">
          <button mat-raised-button color="primary" [disabled]="!shareWithEmail" (click)="shareProject()">Share</button>
        </span>
      </div>

      <div class="black-08 margin-top-20" *ngIf="sharedWith?.length">
        <div class="mat-caption black-06">This project is shared with:</div>
        <ul>
          <li *ngFor="let sharedWithUser of sharedWith">
            <span>{{sharedWithUser.info}}</span>
            <span class="margin-left-10">
              <button mat-icon-button matTooltip="Stop sharing the project with the user" (click)="unshareProject(sharedWithUser)">
                <fa-icon class="black-08" [icon]="['fas', 'trash']"></fa-icon>
              </button>
            </span>
          </li>
        </ul>
      </div>
    </div>
  </app-data-widget>

  <app-data-widget [widgetCaption]="'Change owner'" *ngIf="isOwner">
    <div *ngIf="project">
      <div class="flex black-08">
        <mat-form-field class="flex-1">
          <mat-label>Email address of another registered user</mat-label>
          <input matInput placeholder="Enter an email address to trasnfer the project" type="email" [(ngModel)]="passProjectEmail"
            (change)="passProjectError = ''">
          <mat-hint *ngIf="passProjectError"><span class="color-warning">{{passProjectError}}</span></mat-hint>
        </mat-form-field>
        <span class="margin-left-10">
          <button mat-raised-button color="primary" [disabled]="!passProjectEmail" (click)="passProject()">Change</button>
        </span>
      </div>
    </div>
  </app-data-widget>

  <div class="divider-container">
    <mat-divider></mat-divider>
  </div>

  <div class="project-actions" *ngIf="isOwner">
    <button mat-raised-button (click)="deleteProject()">
      <span class="margin-right-10"><fa-icon class="black-08" [icon]="['fas', 'trash']"></fa-icon></span>Delete project
    </button>
  </div>
</div>