import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AuthService } from "../../services/auth.service";
import { Router } from "@angular/router";
import { FormControl } from "@angular/forms";
import { ErrorStateMatcher } from "@angular/material/core";
import { FormGroupDirective } from "@angular/forms";
import { NgForm } from "@angular/forms";

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent {
  registerForm: FormGroup;
  errorMessage: string = '';
  matcher = new SameStateMatcher();

  constructor(
    private authService: AuthService,
    private router: Router,
    private fb: FormBuilder) {
    this.createForm();
  }

  createForm() {
    this.registerForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, RegisterComponent.strong]],
      passwordConfirm: [''],
      policyConcern: [false, Validators.requiredTrue],
      recaptcha: [null, Validators.required]
    }, { validator: this.checkPasswords });
  }

  tryRegister(value: { [key: string]: any }) {

    this.authService.doRegister(value)
      .then(res => {
        this.authService.doSendVerificationEmail();
        this.router.navigate(['/projects']);
      }, err => {
        console.log(err);
        this.registerForm.controls.password.setValue('');
        this.registerForm.controls.password.markAsUntouched();
        this.registerForm.controls.passwordConfirm.setValue('');
        this.registerForm.controls.passwordConfirm.markAsUntouched();
        this.errorMessage = err.message;
      })
  }

  public static strong(control: FormControl): { [key: string]: boolean } {
    let isNotShort = control.value && control.value.length >= 8;
    let hasNumber = /\d/.test(control.value);
    let hasUpper = /[A-Z]/.test(control.value);
    let hasLower = /[a-z]/.test(control.value);

    const valid = isNotShort;
    if (!valid) {
      return { strong: true };
    }
    return null;
  }

  checkPasswords(group: FormGroup) { // here we have the 'passwords' group
    let pass = group.controls.password.value;
    let passwordConfirm = group.controls.passwordConfirm.value;

    return pass === passwordConfirm ? null : { notSame: true }
  }

  resolved(captchaResponse: string) {
    //console.log(`Resolved captcha with response ${captchaResponse}:`);
  }

  navigateToLogin() {
    this.router.navigate(['/login']);
  }
}

export class SameStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const hasError = !!(control && control.dirty && control.parent && control.parent.hasError('notSame'));
    return hasError;
  }
}