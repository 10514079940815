<div class="top-navigation-container">
  <div class="top-navigation-logo">
    <a href="">
      <img src="assets/img/logo-geoapify-myprojects.png" srcset="assets/img/logo-geoapify-myprojects-high.png 2x"
        alt="Geoapify My projects" />
    </a>
  </div>
  <div class="top-navigation-menu-item" *ngIf="isUserLoggedIn">
    <button mat-mini-fab [matMenuTriggerFor]="menu">
      <fa-icon [icon]="['fas', 'user']"></fa-icon>
    </button>
    <mat-menu class="margin-top-10" #menu="matMenu">
      <div class="top-menu-item ">
        <div class="margin-top-10 mat-caption black-06 left">Welcome,</div>
        <div class="margin-bottom-10 mat-body black-08" *ngIf="displayName">{{displayName}}</div>
      </div>
      <mat-divider></mat-divider>


      <button class="top-menu-item" mat-menu-item [routerLink]="'projects'" *ngIf="isRegularUser">
        <fa-icon [icon]="['fas', 'clone']"></fa-icon><span class="margin-left-10">My projects</span>
      </button>
      <button class="top-menu-item" mat-menu-item [routerLink]="'settings'">
        <fa-icon [icon]="['fas', 'user-cog']"></fa-icon><span class="margin-left-10">Account settings</span>
      </button>


      <mat-divider> </mat-divider>
      <a class="top-menu-item" target="_blank" rel="noopener noreferrer" mat-menu-item href="https://geoapify.com">
        <span>Go to Geoapify.com</span>
      </a>
      <a class="top-menu-item" target="_blank" rel="noopener noreferrer" mat-menu-item
        href="https://apidocs.geoapify.com">
        <span>Go to API docs</span>
      </a>
      <button class="top-menu-item" mat-menu-item (click)="signOut()">
        <fa-icon [icon]="['fas', 'sign-out-alt']"></fa-icon><span class="margin-left-10">Sign out</span>
      </button>
    </mat-menu>
  </div>


  <div class="top-navigation-menu-item go-to-button">
    <a target="_blank" rel="noopener noreferrer" mat-button href="https://apidocs.geoapify.com">
      <span class="white-08">Go to docs</span>
    </a>
  </div>

  <div class="top-navigation-menu-item go-to-button">
    <a target="_blank" rel="noopener noreferrer" mat-button href="https://geoapify.com">
      <span class="white-08">Go to Geoapify.com</span>
    </a>
  </div>


</div>