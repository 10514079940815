import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-manifold-token-timeout',
  templateUrl: './manifold-token-timeout.component.html',
  styleUrls: ['./manifold-token-timeout.component.scss']
})
export class ManifoldTokenTimeoutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
