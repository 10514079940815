import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ProjectMinimal } from '../../services/user-data.service';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ProjectService } from '../../services/project.service';

@Component({
  selector: 'app-new-project-dialog',
  templateUrl: './new-project-dialog.component.html',
  styleUrls: ['./new-project-dialog.component.scss']
})
export class NewProjectDialogComponent implements OnInit {

  newProjectForm: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<NewProjectDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private formBuilder: FormBuilder,
    private projectsService: ProjectService) {
    this.createForm();
  }
  
  createForm() {
    this.newProjectForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.maxLength(255)]],
      type: ['api', [Validators.required]]
    });
  }

  onlyLettersAndNumbers(control: FormControl) {
    if (/^[A-Za-z0-9 ]+$/.test(control.value)) {
      return null;
    }

    return { notValid: true};
  }

  ngOnInit() {
  }

  okClicked() {
    const formValue = this.newProjectForm.value;
    const newProject = {
      name: formValue.name,
      type: formValue.type
    }
    this.dialogRef.close(newProject);
  }
}
