import { Component, OnInit, OnDestroy, NgZone, Input, OnChanges, ViewChild, AfterViewInit, SimpleChange, SimpleChanges } from '@angular/core';
import { ApiKeyStatistics, KeyManagementService } from 'src/app/user/services/key-management.service';
import { ApiKey, Project } from 'src/app/user/services/project.service';
import { FormControl } from '@angular/forms';
import { OverviewStatisticsComponent } from '../overview-statistics/overview-statistics.component';

@Component({
  selector: 'app-keys-statistics',
  templateUrl: './keys-statistics.component.html',
  styleUrls: ['./keys-statistics.component.scss']
})
export class KeysStatistics implements OnInit, OnDestroy, AfterViewInit {

  @Input()
  project: Project;

  @Input()
  apiKeys: string[];

  @ViewChild('chart') chartComponent: OverviewStatisticsComponent;

  statistics: ApiKeyStatistics = {
    tiles: 0,
    isoline: 0,
    routing: 0,
    places: 0,
    placedetails: 0,
    geocoding: 0,
    geolocation: 0,
    batch: 0,
    boundaries: 0,
    icon: 0,
    mapmatching: 0,
    routematrix: 0,
    routeplanner: 0,
    staticmap: 0,
    datastore: 0
  };
  
  costs: ApiKeyStatistics = {
    tiles: 0,
    isoline: 0,
    routing: 0,
    places: 0,
    placedetails: 0,
    geocoding: 0,
    geolocation: 0,
    batch: 0,
    boundaries: 0,
    icon: 0,
    mapmatching: 0,
    routematrix: 0,
    routeplanner: 0,
    staticmap: 0,
    datastore: 0
  };

  totalRequests = 0;
  totalCosts = 0;

  statisticsToDisplay = ['tiles', 'staticmap', 'icon', 'geocoding', 'geolocation', 'places', 'placedetails', 'boundaries', 'isoline', 'routing', 'mapmatching', 'routematrix', 'routeplanner', 'batch'];
  statisticsNames = {
    tiles: 'Map tiles',
    isoline: 'Isolines API',
    routing: 'Routing API',
    places: 'Places API',
    boundaries: 'Boundaries API',
    geocoding: 'Geocoding API',
    datastore: 'Datastore',
    staticmap: 'Static Maps API',
    icon: 'Marker Icon API',
    geolocation: 'IP Geolocation API',
    placedetails: 'Place Details API',
    mapmatching: 'Map Matching API',
    routematrix: 'Route Matrix API',
    routeplanner: 'Route Planner API',
    batch: 'Batch API calls'
  };

  keyFilter: string = 'all';

  fromDate: FormControl;
  toDate: FormControl;

  oneYearAgo: Date;

  showSpinner: boolean;

  initialized: boolean;

  constructor(private keyManagementService: KeyManagementService, private ngZone: NgZone) {
    let fromDate = new Date();
    fromDate.setMonth(fromDate.getMonth() - 1);

    let toDate = new Date();
    toDate.setHours(24);
    toDate.setMinutes(0);
    toDate.setSeconds(0);

    this.oneYearAgo = new Date();
    this.oneYearAgo.setFullYear(this.oneYearAgo.getFullYear() - 1);

    this.fromDate = new FormControl(fromDate);
    this.toDate = new FormControl(toDate);
  }

  ngAfterViewInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.project && changes.project.currentValue && !this.initialized) {
      this.initialized = true;
      this.updateByAPIStatistics();
    }
  }

  ngOnInit() {
  }

  ngOnDestroy() {
  }

  updateByAPIStatistics() {
    this.showSpinner = true;

    const statistics: ApiKeyStatistics = {
      tiles: 0,
      isoline: 0,
      routing: 0,
      places: 0,
      placedetails: 0,
      geocoding: 0,
      geolocation: 0,
      batch: 0,
      boundaries: 0,
      icon: 0,
      mapmatching: 0,
      routematrix: 0,
      routeplanner: 0,
      staticmap: 0,
      datastore: 0
    };

    const costs: ApiKeyStatistics = {
      tiles: 0,
      isoline: 0,
      routing: 0,
      places: 0,
      placedetails: 0,
      geocoding: 0,
      geolocation: 0,
      batch: 0,
      boundaries: 0,
      icon: 0,
      mapmatching: 0,
      routematrix: 0,
      routeplanner: 0,
      staticmap: 0,
      datastore: 0
    }

    let totalCosts = 0;
    let totalRequests = 0;

    let fromDateWith0 = new Date(this.fromDate.value);
    fromDateWith0.setHours(0);
    fromDateWith0.setMinutes(0);
    fromDateWith0.setSeconds(0);

    let toDateWith24 = new Date(this.toDate.value);
    toDateWith24.setHours(24);
    toDateWith24.setMinutes(0);
    toDateWith24.setSeconds(0);

    const fromDateStr = this.formatDate(fromDateWith0);
    const toDateStr = this.formatDate(toDateWith24);

    const keys = this.apiKeys.filter(key => this.keyFilter === 'all' || key === this.keyFilter);

    const promises = [];
    keys.forEach((key) => {
      promises.push(this.keyManagementService.getApiKeyStatistics(key, fromDateStr, toDateStr));
    });

    Promise.all(promises).then(dataArr => {

      dataArr.forEach((data, index) => {

        if (!data.result) {
          return;
        }

        const result = data.result[keys[index]];
        this.statisticsToDisplay.forEach(field => {
          statistics[field] += parseInt(result[field]?.count || 0);
          costs[field] += parseInt(result[field]?.cost_credits || 0);

          totalRequests += parseInt(result[field]?.count || 0);
          totalCosts += parseInt(result[field]?.cost_credits || 0);
        });
      });

      this.ngZone.run(() => {
        this.statistics = statistics;
        this.costs = costs;
        this.totalCosts = totalCosts;
        this.totalRequests = totalRequests;
        this.showSpinner = false;
      });
    });
  }

  updateStatistics() {
    this.updateByAPIStatistics();
    this.chartComponent.updateStatistics();
  }

  private formatDate(date) {
    return new Date(date).toISOString();
  }
}
