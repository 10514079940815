import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './user/components/login/login.component';
import { AuthGuard } from './user/canActivate/auth-guard';
import { RestorePasswordComponent } from './user/components/restore-password/restore-password.component';
import { RegisterComponent } from './user/components/register/register.component';
import { UserGuard } from './user/canActivate/user-guard';
import { UserProjectsComponent } from './user/components/user-projects/user-projects.component';
import { UserSettingsComponent } from './user/components/user-settings/user-settings.component';
import { ApiProjectComponent } from './api-project/components/api-project/api-project.component';
import { ManifoldDashboardComponent } from './manifold-project/components/manifold-dashboard/manifold-dashboard.component';
import { ManifoldTokenTimeoutComponent } from './manifold-project/components/manifold-token-timeout/manifold-token-timeout.component';
import { ManifoldGuard } from './manifold-project/canActivate/manifold-guard';


const routes: Routes = [
  { path: 'login', component: LoginComponent, canActivate: [AuthGuard]},
  { path: 'restore', component: RestorePasswordComponent, canActivate: [AuthGuard]},
  { path: 'register', component: RegisterComponent, canActivate: [AuthGuard]},
  { path: 'projects', component: UserProjectsComponent, canActivate: [UserGuard]},
  { path: 'api/:id/:panelId', component: ApiProjectComponent, canActivate: [UserGuard]},
  { path: 'api/:id', redirectTo: 'api/:id/keys'},
  { path: 'settings', component: UserSettingsComponent, canActivate: [UserGuard]},
  { path: 'manifold/dashboard/:panelId', component: ManifoldDashboardComponent, canActivate: [ManifoldGuard]},
  { path: 'manifold/dashboard', redirectTo: 'manifold/dashboard/keys'},
  { path: 'manifold/login', component: ManifoldTokenTimeoutComponent},
  { path: '**', redirectTo: 'login'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
