import { Component, OnInit, Input, Output, EventEmitter, NgZone } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { KeyManagementService } from 'src/app/user/services/key-management.service';
import { ApiKeyData } from 'src/app/user/services/project.service';
import { SinglePropertyChangeDialogComponent } from 'src/app/common-components/components/single-property-change-dialog/single-property-change-dialog.component';
import { ConfirmationDialogComponent } from 'src/app/common-components/components/confirmation-dialog/confirmation-dialog.component';
import { UserDataService } from 'src/app/user/services/user-data.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-key-item',
  templateUrl: './key-item.component.html',
  styleUrls: ['./key-item.component.scss']
})
export class KeyItemComponent implements OnInit {

  @Input()
  projectData: string;

  @Input()
  openStates: {[key: string]: boolean};

  @Input()
  key: string;

  @Input()
  description: string;

  @Input()
  onlyKey: boolean;

  @Output()
  removeKey = new EventEmitter<string>();

  @Output()
  changeDescription = new EventEmitter<string>();

  apiKeyData: ApiKeyData;

  minimized: boolean = true;
  inProgress: boolean = false;

  ipAddressHint = "Client/browser IP address (IPv4, IPv6). Supports regular expressions. For example, 10.20.30.(1[0-9])";
  httpReferrerHint = "Referrer header sent by the browser (full URL of the page that sent request). For example, https://mywebsite.com/import or https://mapifator.com/import.*";
  originsHint = "Origin header sent by the browser (usually protocol + domain name). For example, https://mywebsite.com";
  corsHint = "Force specific 'Access-Control-Allow-Origin' response header value instead of default permissive '*'";

  constructor(private keyManagementService: KeyManagementService,
    private dialog: MatDialog,
    private ngZone: NgZone,
    private snackBar: MatSnackBar) {
  }

  setState(isMinimized) {
    this.minimized = isMinimized;
    this.openStates[this.key] = !isMinimized;
  }

  editDescription() {
    const dialogRef = this.dialog.open(SinglePropertyChangeDialogComponent, {
      width: '400px',
      data: {
        label: 'New description',
        data: this.description
      }
    });

    dialogRef.afterClosed().subscribe(newValue => {
      if (newValue) {
        this.changeDescription.emit(newValue);


      }
    });
  }

  editAllowedOrigin() {
    const dialogRef = this.dialog.open(SinglePropertyChangeDialogComponent, {
      width: '400px',
      data: {
        label: 'Set CORS Access-Control-Allow-Origin',
        data: this.apiKeyData.restriction_allowed_origin_header,
        allowEmpty: true
      }
    });

    dialogRef.afterClosed().subscribe(newValue => {

      if (newValue) {
        this.apiKeyData.restriction_allowed_origin_header = newValue;
        this.keyManagementService.updateApiKey(this.apiKeyData.id, {
          restriction_allowed_origin_header: this.apiKeyData.restriction_allowed_origin_header
        }, this.projectData);
      } else if (newValue === "") {
        this.apiKeyData.restriction_allowed_origin_header = "*"
        this.keyManagementService.updateApiKey(this.apiKeyData.id, {
          restriction_allowed_origin_header: this.apiKeyData.restriction_allowed_origin_header
        }, this.projectData);
      }
    });
  }

  editRestrictionAddress(i) {
    const dialogRef = this.dialog.open(SinglePropertyChangeDialogComponent, {
      width: '400px',
      data: {
        label: 'Set IP address (e.g. 123.66.87.44)',
        data: this.apiKeyData.restriction_addresses[i]
      }
    });

    dialogRef.afterClosed().subscribe(newValue => {
      if (newValue) {
        this.apiKeyData.restriction_addresses[i] = newValue;
        this.keyManagementService.updateApiKey(this.apiKeyData.id, {
          restriction_addresses: this.apiKeyData.restriction_addresses
        }, this.projectData);
      }
    });
  }

  addRestrictionAddress() {
    const dialogRef = this.dialog.open(SinglePropertyChangeDialogComponent, {
      width: '400px',
      data: {
        label: 'Add IP address (e.g. 123.66.87.44)',
        data: ''
      }
    });

    dialogRef.afterClosed().subscribe(newValue => {
      if (newValue) {
        this.apiKeyData.restriction_addresses = this.apiKeyData.restriction_addresses || [];
        this.apiKeyData.restriction_addresses.push(newValue);
        this.keyManagementService.updateApiKey(this.apiKeyData.id, {
          restriction_addresses: this.apiKeyData.restriction_addresses
        }, this.projectData);
      }
    });
  }

  removeRestrictionAddress(index: number) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: { title: "Allowed IP address", content: `Remove "${this.apiKeyData.restriction_addresses[index]}" from allowed addresses?` }
    });

    dialogRef.afterClosed().subscribe(okPressed => {
      if (okPressed) {
        this.apiKeyData.restriction_addresses.splice(index, 1);
        this.keyManagementService.updateApiKey(this.apiKeyData.id, {
          restriction_addresses: this.apiKeyData.restriction_addresses
        }, this.projectData);
      }
    });
  }

  removeRestrictionReferrer(index: number) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: { title: "Allowed referrers", content: `Remove "${this.apiKeyData.restriction_referrers[index]}" from allowed referrers?` }
    });

    dialogRef.afterClosed().subscribe(okPressed => {
      if (okPressed) {
        this.apiKeyData.restriction_referrers.splice(index, 1);
        this.keyManagementService.updateApiKey(this.apiKeyData.id, {
          restriction_referrers: this.apiKeyData.restriction_referrers
        }, this.projectData);
      }
    });
  }

  editRestrictionReferrer(index) {
    const dialogRef = this.dialog.open(SinglePropertyChangeDialogComponent, {
      width: '400px',
      data: {
        label: 'Add HTTP referrer (e.g. https://mysite\.com/.*)',
        data: this.apiKeyData.restriction_referrers[index]
      }
    });

    dialogRef.afterClosed().subscribe(newValue => {
      if (newValue) {
        this.apiKeyData.restriction_referrers[index] = newValue;
        // add to configuration
        this.keyManagementService.updateApiKey(this.apiKeyData.id, {
          restriction_referrers: this.apiKeyData.restriction_referrers
        }, this.projectData);
      }
    });
  }

  addRestrictionReferrer() {
    const dialogRef = this.dialog.open(SinglePropertyChangeDialogComponent, {
      width: '400px',
      data: {
        label: 'Add HTTP referrer (e.g. https://mysite\.com/.*)',
        data: ''
      }
    });

    dialogRef.afterClosed().subscribe(newValue => {
      if (newValue) {
        this.apiKeyData.restriction_referrers = this.apiKeyData.restriction_referrers || [];
        this.apiKeyData.restriction_referrers.push(newValue);
        // add to configuration
        this.keyManagementService.updateApiKey(this.apiKeyData.id, {
          restriction_referrers: this.apiKeyData.restriction_referrers
        }, this.projectData);
      }
    });
  }

  removeRestrictionOrigin(index: number) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: { title: "Allowed origins", content: `Remove "${this.apiKeyData.restriction_origins[index]}" from allowed origins?` }
    });

    dialogRef.afterClosed().subscribe(okPressed => {
      if (okPressed) {
        this.apiKeyData.restriction_origins.splice(index, 1);
        this.keyManagementService.updateApiKey(this.apiKeyData.id, {
          restriction_origins: this.apiKeyData.restriction_origins
        }, this.projectData);
      }
    });
  }

  editRestrictionOrigin(index) {
    const dialogRef = this.dialog.open(SinglePropertyChangeDialogComponent, {
      width: '400px',
      data: {
        label: 'Add Origin header (e.g. https://mysite.com )',
        data: this.apiKeyData.restriction_origins[index]
      }
    });

    dialogRef.afterClosed().subscribe(newValue => {
      if (newValue) {
        this.apiKeyData.restriction_origins[index] = newValue;
        // add to configuration
        this.keyManagementService.updateApiKey(this.apiKeyData.id, {
          restriction_origins: this.apiKeyData.restriction_origins
        }, this.projectData);
      }
    });
  }

  addRestrictionOrigin() {
    const dialogRef = this.dialog.open(SinglePropertyChangeDialogComponent, {
      width: '400px',
      data: {
        label: 'Add Origin header (e.g. https://mysite.com )',
        data: ''
      }
    });

    dialogRef.afterClosed().subscribe(newValue => {
      if (newValue) {
        this.apiKeyData.restriction_origins = this.apiKeyData.restriction_origins || [];
        this.apiKeyData.restriction_origins.push(newValue);
        // add to configuration
        this.keyManagementService.updateApiKey(this.apiKeyData.id, {
          restriction_origins: this.apiKeyData.restriction_origins
        }, this.projectData);
      }
    });
  }

  ngOnInit() {
    this.minimized = !this.openStates[this.key];
    this.inProgress = true;
    this.keyManagementService.getApiKeyData(this.key).then((apiKeyData: any) => {
      this.ngZone.run(() => {
        this.apiKeyData = apiKeyData;
        this.inProgress = false;

      });
    });
  }

  removeAPIKey() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: {
        title: "Delete API Key",
        content: "Delete API key irrecoverably?"
      }
    });

    dialogRef.afterClosed().subscribe(okPressed => {
      if (okPressed) {
        this.removeKey.emit(this.key);
      }
    });
  }

  copyToClipboard(text: string) {
    var input = document.createElement('input');
    input.setAttribute('value', text);
    document.body.appendChild(input);
    input.select();
    var result = document.execCommand('copy');
    document.body.removeChild(input);


    this.snackBar.open("Copied", "OK", {
      duration: 2000,
    });  

    return result;
  }
}
