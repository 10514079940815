import { Injectable } from '@angular/core';
import { getFunctions, httpsCallable, HttpsCallable } from 'firebase/functions';

@Injectable({
  providedIn: 'root'
})
export class FunctionsService {

  private removeSubscriptionFunc: HttpsCallable = httpsCallable(getFunctions(), 'removeSubscription');
  private deleteProjectFunc: HttpsCallable = httpsCallable(getFunctions(), 'deleteProject');
  private shareProjectFunc: HttpsCallable = httpsCallable(getFunctions(), 'shareProject');
  private unshareProjectFunc: HttpsCallable = httpsCallable(getFunctions(), 'unshareProject');
  private passProjectFunc: HttpsCallable = httpsCallable(getFunctions(), 'passProject');
  private deleteAccountFunc: HttpsCallable = httpsCallable(getFunctions(), 'deleteUserProjectsAndKeysCall');

  constructor() { }

  public deleteProject(projectId) {
    return this.deleteProjectFunc({
      projectId: projectId
    });
  }

  public removeSubscription(projectId: string, subscriptionId: string) {
    return this.removeSubscriptionFunc({
      projectId: projectId,
      subscriptionId: subscriptionId
    });
  }

  public shareProject(projectId: string, email: string) {
    return this.shareProjectFunc({
      projectId: projectId,
      email: email
    });
  }

  public unshareProject(projectId: string, user: string) {
    return this.unshareProjectFunc({
      projectId: projectId,
      user: user
    });
  }

  public passProject(projectId: string, email: string) {
    return this.passProjectFunc({
      projectId: projectId,
      email: email
    });
  }

  public deleteAccount() {
    return this.deleteAccountFunc();
  }
}
