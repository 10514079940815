import { Component, OnInit } from '@angular/core';
import { UserDataService, UserDocument } from '../../services/user-data.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from 'src/app/common-components/components/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-user-additional-data',
  templateUrl: './user-additional-data.component.html',
  styleUrls: ['./user-additional-data.component.scss']
})
export class UserAdditionalDataComponent implements OnInit {

  userDocument: UserDocument;

  constructor(private userDataService: UserDataService, private dialog: MatDialog) {
    this.updateData(userDataService.getUserDocument());

    userDataService.getUserDocumentChangeEvent().subscribe((userDocument: UserDocument) => {
      this.updateData(userDocument);
    });
  }

  updateData(userDocument: UserDocument) {
    this.userDocument = userDocument;
  }

  ngOnInit() {
  }

  toggleSubscription() {
    if (this.userDocument.newsletterConsent) {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '350px',
        data: {title: "Unsubscribe newsletters", content: "You may miss important information, news, and our special offers."}
      });

      dialogRef.afterClosed().subscribe(okPressed => {
        if (okPressed) {
          this.userDataService.setUserDocumentProperties({
            newsletterConsent: false
          });
        }
      });

    } else {
      this.userDataService.setUserDocumentProperties({
        newsletterConsent: true
      });
    }
  }
}
