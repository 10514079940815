import { Injectable, EventEmitter } from '@angular/core';
import { User } from 'firebase/auth';
import { collection, doc, DocumentData, DocumentReference, getDoc, getFirestore, onSnapshot, setDoc, updateDoc } from 'firebase/firestore';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})

export class UserDataService {
  private userDocumentRef: DocumentReference<DocumentData>
  private userDocument: UserDocument;
  private user: User;

  private userDocumentChangeEventEmitter = new EventEmitter<UserDocument>();
  private unsubscribeSnapshotListen;

  constructor(private authService: AuthService) {
    authService.getUserPromise().then((user) => {

      if (user && authService.isRegularUser()) {
        const dataRef = doc(collection(getFirestore(), 'users'), user.uid);
        getDoc(dataRef).then((dataSnapshot) => {
          if (!dataSnapshot.exists) {
            // create document!
            setDoc(dataRef, {}, { merge: true }).then(() => {
              this.init(user);
            });
          } else {
            this.init(user);
          }
        }, (error) => {
          console.log(error);
          setDoc(dataRef, {}, { merge: true }).then(() => {
            this.init(user);
          });
        });
      }
    });

    authService.getUserChangeEvent().subscribe((user: User) => {
      this.init(user);
    });
  }

  public getUserId() {
    return this.user.uid;
  }

  public init(user) {
    if (this.unsubscribeSnapshotListen) {
      this.unsubscribeSnapshotListen();
      this.unsubscribeSnapshotListen = null;
    }

    this.user = user;
    if (user) {
      this.userDocumentRef = doc(collection(getFirestore(), 'users'), user.uid);
      this.unsubscribeSnapshotListen = onSnapshot(this.userDocumentRef, (snapshot) => {
        this.userDocument = snapshot.data() as UserDocument;
        this.userDocumentChangeEventEmitter.emit(this.userDocument);
      }, error => console.log(error));
    } else {
      this.userDocument = null;
      this.userDocumentRef = null;
      this.userDocumentChangeEventEmitter.emit(null);
    }
  }

  public setUserDocumentProperties(properties: { [key: string]: any }): Promise<void> {
    return updateDoc(this.userDocumentRef, properties);
  }

  public getUserDocumentChangeEvent(): EventEmitter<UserDocument> {
    return this.userDocumentChangeEventEmitter;
  }

  public getUserDocument(): UserDocument {
    return this.userDocument;
  }

  public getProjects(): ProjectMinimal[] {
    const projects = Object.keys(this.userDocument.projects || {}).map((key) => this.userDocument.projects[key]);

    projects.sort((a: ProjectMinimal, b: ProjectMinimal) => {
      return (a.name || '').localeCompare(b.name);
    });

    return projects;
  }

  public addNewProject(project: ProjectMinimal): Promise<void> {
    const projectupdate = {};
    projectupdate[`projects.${project.id}`] = project;
    return updateDoc(this.userDocumentRef, projectupdate);
  }

  public changeProjectName(projectId: string, newName: string): Promise<void> {
    const projectupdate = {};
    projectupdate[`projects.${projectId}.name`] = newName;
    return updateDoc(this.userDocumentRef, projectupdate);
  }
}

export interface UserDocument {
  newsletterConsent?: boolean;
  projects: { [key: string]: ProjectMinimal };
}


export interface ProjectMinimal {
  name: string;
  id: string;
  type: 'api' | 'mapifator';
  shared?: boolean;
}