import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApiProjectComponent } from './components/api-project/api-project.component';
import { ApiProjectNavigationComponent } from './components/api-project-navigation/api-project-navigation.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatRadioModule } from '@angular/material/radio';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ApiProjectSettingsComponent } from './components/api-project-settings/api-project-settings.component';
import { ApiProjectKeysComponent } from './components/api-project-keys/api-project-keys.component';
import { ApiProjectPricingPlansComponent } from './components/api-project-pricing-plans/api-project-pricing-plans.component';
import { CommonComponentsModule } from '../common-components/common-components.module';
import { SubscriptionChangeComponent } from './components/subscription-change/subscription-change.component';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatTooltipModule } from '@angular/material/tooltip';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

@NgModule({
  declarations: [
    ApiProjectComponent,
    ApiProjectNavigationComponent,
    ApiProjectSettingsComponent,
    ApiProjectKeysComponent,
    ApiProjectPricingPlansComponent,
    SubscriptionChangeComponent],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatSelectModule,
    MatTooltipModule,
    MatIconModule,
    MatTabsModule,
    MatButtonToggleModule,
    MatDividerModule,
    MatRadioModule,
    CommonComponentsModule,
    MatProgressSpinnerModule,
    MatTabsModule,
    FontAwesomeModule
  ]
})
export class ApiProjectModule { 
  constructor(library: FaIconLibrary) {
    library.addIcons(faExclamationTriangle);
  }
}
