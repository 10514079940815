<h2 mat-dialog-title>Add new project</h2>
<mat-dialog-content>
  <form class="login-form" [formGroup]="newProjectForm">
    <mat-form-field class="control-fullwidth">
      <input matInput formControlName="name" class="form-control" placeholder="Project name">
      <mat-error *ngIf="newProjectForm.get('name').invalid">Must be non-empty and not longer than 255 characters</mat-error>
    </mat-form-field>

    <mat-form-field class="control-fullwidth" *ngIf="false">
      <mat-select formControlName="type" class="form-control" placeholder="Project type" disabled>
        <mat-option value="api">
          Geoapify APIs
        </mat-option>
        <mat-option value="mapifator">
          Mapifator
        </mat-option>
      </mat-select>
      <mat-error *ngIf="newProjectForm.get('type').invalid">Must be non-empty</mat-error>
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="new-project-dialog-buttons">
    <button mat-raised-button mat-dialog-close>Cancel</button>
    <button mat-raised-button (click)="okClicked()" [disabled]="newProjectForm?.invalid" color="accent">OK</button>
  </div>
</mat-dialog-actions>