<div class="pricing-plan-container">
  <div class="header">
    <div class="name mat-body" [style.color]="plan.color">{{ plan.label }}
    </div>
    <div class="items" *ngIf="plan.credits">
      <div class="item">
        <span class="mat-body black-08">{{ plan.credits.toLocaleString('en-US') }} credits / day</span>
        <span class="margin-left-10">
          <fa-icon class="black-06" [icon]="['fas', 'info-circle']" [matTooltip]="generateTooltip(plan.items)"
            matTooltipClass="items-tooltip"></fa-icon>
        </span>
      </div>
      <mat-divider> </mat-divider>
    </div>
  </div>

  <div class="price">
    <ng-container *ngIf="plan.price">
      <div class="price-value mat-body" *ngIf="plan.price[billingCycle]">
        <span class="margin-right-5 mat-caption" *ngIf="plan.price.from">from</span>
        <span *ngIf="plan.price[billingCycle][currency] && plan.id !== 'geoapify-api-custom'">{{ currency === 'eur' ?
          plan.price[billingCycle][currency].toLocaleString() + '€' : '$' +
          plan.price[billingCycle][currency].toLocaleString()}}</span>
        <span
          *ngIf="!plan.price[billingCycle][currency] &&  plan.id !== 'geoapify-api-custom' ">{{plan.price[billingCycle]['eur']}}€</span>
        <span *ngIf="plan.id === 'geoapify-api-custom' ">Custom price</span>
      </div>
      <div class="mat-caption black-06" *ngIf="plan.price[billingCycle] && plan.id !== 'geoapify-api-custom'">
        {{ billingCycle === 'monthly' ? 'billed monthly' : 'billed yearly' }}</div>

      <div class="mat-caption mat-body" *ngIf="!plan.price[billingCycle] && plan.id !== 'geoapify-api-custom'">The plan
        doesn't have options the billing cycle selected</div>

      <!-- PAY_AS_YOU_GO_TOGGLE
      <div class="overquota margin-top-10" *ngIf="plan.overquota">
        <div class="mat-caption black-06" *ngIf="currency === 'eur'">from {{(plan.credits + 1).toLocaleString('en-US')}}
          credits: {{plan.overquota[currency]}}€ per 1k credits<span *ngIf="!plan.overquotaIncluded">*</span></div>
        <div class="mat-caption black-06" *ngIf="currency !== 'eur'">from {{(plan.credits + 1).toLocaleString('en-US')}}
            credits: ${{plan.overquota[currency]}} per 1k credits<span *ngIf="!plan.overquotaIncluded">*</span></div>
      </div>
      -->
    </ng-container>
  </div>

  <div class="actions">

    <a class="blue-button" mat-flat-button *ngIf="contactUs" href="mailto:info@geoapify.com">CONTACT US</a>
    <a class="purple-button" *ngIf="onlyInfo" mat-flat-button target="_blank" rel="noopener noreferrer"
      href="https://myprojects.geoapify.com">SIGN UP</a>

    <button *ngIf="!isCurrentPlan && !onlyInfo && !contactUs" [color]="custom ? 'accent': 'primary'" mat-raised-button
      (click)="subscribeClicked()" [disabled]="plan.price && !plan.price[billingCycle]">Select plan</button>
    <button class="current" *ngIf="isCurrentPlan && !onlyInfo && !renewalDate && !expirationDate" [disabled]="true"
      mat-flat-button>Current plan</button>
    <div *ngIf="isCurrentPlan && !onlyInfo && (renewalDate || expirationDate)">
      <div class="mat-body-2 black-08">Current plan</div>
      <div *ngIf="renewalDate" class="mat-caption color-green">Renews on {{renewalDate}}</div>
      <div *ngIf="!renewalDate && expirationDate" class="mat-caption color-warning">Expires on {{expirationDate}}</div>
    </div>
  </div>

  <div class="content">
    <div class="items" *ngIf="!plan.credits">
      <mat-divider> </mat-divider>

      <div class="item mat-caption" *ngFor="let item of plan.items">{{item}}</div>
    </div>
    <mat-divider> </mat-divider>
    <div class="conditions">
      <div class="condition" *ngFor="let condition of plan.conditions" [matTooltip]="condition.hint">
        <span class="icon">
          <fa-icon [icon]="['fas', 'check']" *ngIf="condition.provided === 'yes'" [style.color]="'#00b300'"></fa-icon>
          <fa-icon [icon]="['fas', 'times']" *ngIf="condition.provided === 'no'" [style.color]="'#ff4d4d'"></fa-icon>
          <fa-icon [icon]="['fas', 'check']" *ngIf="condition.provided === 'partially'" [style.color]="'#ff9900'">
          </fa-icon>
        </span>
        <span class="label mat-caption">{{condition.label}}</span>
      </div>
    </div>
  </div>
</div>